import { Spinner } from "@comic/precog-components";
import { VaporToolbar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Button,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow
} from "@vapor/react-material";
import { compact, intersection, isEmpty, isNil, sumBy } from "lodash";
import { useEffect, useMemo } from "react";
import {
    Controller,
    FormProvider,
    SubmitHandler,
    useForm
} from "react-hook-form";
import { usePaymentsTotalAmount } from "../../../../core/domain/Draft/Payments/hooks";
import {
    useCreatePayment,
    usePayments,
    useTotalToPay
} from "../../../../core/domain/Draft/Payments/queries";

import { useDraft } from "../../../../core/domain/Draft/queries";
import { useFiscalPayments } from "../../../../core/domain/FiscalPayment/queries";
import { FiscalPayment as FiscalPaymentsDto } from "../../../../core/usecases/dtos/FiscalPaymentDto";
import { Payment } from "../../../../core/usecases/dtos/PaymentsDto";
import { PaymentType } from "../../../../utils/appEnums";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import FormattedAmount from "../../FormattedAmount";
import FormattedDate from "../../FormattedDate";
import FormCurrencyField from "../../FormFields/FormCurrencyField";
import NotFoundBanner from "../../NotFoundBanner";
import TableHeadCell from "../../TableHead/TableHeadCell";
import TableHeadText from "../../TableHead/TableHeadText";

interface PaymentAmount {
    paymentId: string;
    amount: number;
    selected: boolean;
    fiscalPayment: FiscalPaymentsDto;
}

interface FormValues {
    payments: PaymentAmount[];
}

const fs = getFormattedStringWithScope("components.Accounting.PaymentsDrawer");

function getAlreadySelectedPaymentIds(
    payments: Payment[],
    fiscalPayments: FiscalPaymentsDto[]
) {
    return intersection(
        payments.map((payment) => payment.registeredPaymentId),
        fiscalPayments.map((fiscalPayment) => fiscalPayment.key)
    );
}

type FiscalPaymentsProps = {
    draftId: string;
    type: "CREDIT_NOTE" | "ADVANCE";
    onCreationSuccess?: () => void;
    onCancel?: () => void;
};
export default function FiscalPayments({
    draftId,
    type,
    onCreationSuccess,
    onCancel
}: FiscalPaymentsProps) {
    const { data } = usePayments({
        draftId,
        paymentType: type
    });
    const payments = useMemo(() => data ?? [], [data]);
    const paymentType =
        type === "CREDIT_NOTE" ? PaymentType.CREDIT_NOTE : PaymentType.PAYMENT;
    const { data: totalToPay, isSuccess: isSuccessTotalToPay } =
        useTotalToPay(draftId);
    const paymentsTotalAmount = usePaymentsTotalAmount(draftId);
    const { data: draft } = useDraft(draftId);
    const { refCurrency } = draft ?? { refCurrency: "EUR" };
    const { mutateAsync, isPending: isCreatingPayments } =
        useCreatePayment(draftId);

    const { data: allFiscalPayments, isLoading: isLoadingFiscalPayments } =
        useFiscalPayments({
            active: draft?.doc.active!,
            businessId: draft?.businessId!,
            draftId: draft?.id!,
            factType: draft?.factType!,
            reasonId: draft?.reasonId!,
            types: [paymentType!]
        });

    const fiscalPayments = allFiscalPayments.filter((fiscalPayment) =>
        [draft?.customerTaxId, draft?.supplierTaxId].includes(
            fiscalPayment.taxId
        )
    );

    const methods = useForm<FormValues>({
        defaultValues: {
            payments: []
        }
    });

    const selectedPaymentIds = compact(
        methods
            .getValues()
            .payments.map((payment) =>
                payment.selected ? payment.paymentId : null
            )
    );

    const paymentAmounts = methods.watch("payments");

    const alreadySelectedPaymentIds = useMemo(
        () => getAlreadySelectedPaymentIds(payments, fiscalPayments),
        [payments, fiscalPayments]
    );

    const alreadySelectedPaymentsAmount = sumBy(
        payments.filter((payment) =>
            alreadySelectedPaymentIds.includes(payment.registeredPaymentId)
        ),
        (payment) => payment.amount
    );

    const fiscalPaymentsAmount = sumBy(paymentAmounts, (payment) =>
        selectedPaymentIds.includes(payment.paymentId) ? payment.amount : 0
    );

    const remainingAmount = isSuccessTotalToPay
        ? totalToPay -
          (paymentsTotalAmount - alreadySelectedPaymentsAmount) -
          fiscalPaymentsAmount
        : 0;

    const onSubmit: SubmitHandler<FormValues> = async ({ payments }) => {
        await mutateAsync(
            payments
                .filter((payment) => payment.selected)
                .map((payment) => ({
                    type: payment.fiscalPayment.type,
                    amount: payment.amount,
                    date: payment.fiscalPayment.docDate,
                    docNumber: payment.fiscalPayment.docNumber,
                    registeredPaymentId: payment.fiscalPayment.key
                }))
        );
        onCreationSuccess && onCreationSuccess();
    };

    useEffect(() => {
        if (payments.length === 0) {
            return;
        }

        methods.setValue(
            "payments",
            fiscalPayments.map((fiscalPayment) => ({
                amount:
                    payments.find(
                        (payment) =>
                            payment.registeredPaymentId === fiscalPayment.key
                    )?.amount ?? 0,
                paymentId: fiscalPayment.key,
                selected: alreadySelectedPaymentIds.includes(fiscalPayment.key),
                fiscalPayment: fiscalPayment
            }))
        );
    }, [
        methods,
        payments,
        fiscalPayments,
        alreadySelectedPaymentIds,
        isLoadingFiscalPayments
    ]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Typography variant="titleSmall" color="#005075">
                    {fs(`title.${paymentType}`)}
                </Typography>
                <Spinner
                    loading={isLoadingFiscalPayments || isCreatingPayments}
                >
                    {isEmpty(fiscalPayments) && !isLoadingFiscalPayments ? (
                        <NotFoundBanner
                            text={`${
                                paymentType ? fs(`no.${paymentType}`) : ""
                            } ${paymentType ? fs(`found.${paymentType}`) : ""}`}
                        />
                    ) : (
                        <Table
                            sx={{
                                marginTop: "16px"
                            }}
                        >
                            <TableHead shadow>
                                <TableHeadCell></TableHeadCell>
                                <TableHeadCell>
                                    <TableHeadText>
                                        {fs("docDate")}
                                    </TableHeadText>
                                </TableHeadCell>
                                <TableHeadCell>
                                    <TableHeadText>
                                        {fs("description")}
                                    </TableHeadText>
                                </TableHeadCell>
                                <TableHeadCell align="right">
                                    <TableHeadText>
                                        {fs("amount")}
                                    </TableHeadText>
                                </TableHeadCell>
                                <TableHeadCell align="right">
                                    <TableHeadText>
                                        {fs("associatedAmount")}
                                    </TableHeadText>
                                </TableHeadCell>
                            </TableHead>
                            <TableBody>
                                {paymentAmounts.map((payment, index) => {
                                    const fiscalPayment = fiscalPayments.find(
                                        (fiscalPayment) =>
                                            fiscalPayment.key ===
                                            payment.paymentId
                                    );
                                    return fiscalPayment ? (
                                        <TableRow key={index}>
                                            <TableCell
                                                sx={{
                                                    maxWidth: "48px"
                                                }}
                                            >
                                                <Controller
                                                    control={methods.control}
                                                    name={`payments.${index}.selected`}
                                                    render={({ field }) => {
                                                        return (
                                                            <Checkbox
                                                                checked={
                                                                    field.value
                                                                }
                                                                onChange={(
                                                                    _,
                                                                    checked
                                                                ) =>
                                                                    field.onChange(
                                                                        checked
                                                                    )
                                                                }
                                                            />
                                                        );
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <FormattedDate
                                                    date={fiscalPayment.date}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {
                                                        fiscalPayment.reasonDescription
                                                    }{" "}
                                                    {fiscalPayment.docNumber}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <FormattedAmount
                                                    amount={
                                                        fiscalPayment
                                                            .remainingAmount
                                                            .amount
                                                    }
                                                    currency={
                                                        fiscalPayment
                                                            .remainingAmount
                                                            .currency
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <FormCurrencyField
                                                    name={`payments.${index}.amount`}
                                                    validate={(amount) =>
                                                        payment.selected
                                                            ? amount !== 0
                                                            : true
                                                    }
                                                    required={true}
                                                    currency={
                                                        fiscalPayment
                                                            .remainingAmount
                                                            .currency
                                                    }
                                                    isAllowed={(values) =>
                                                        isNil(values.floatValue)
                                                            ? true
                                                            : values.floatValue >=
                                                                  0 &&
                                                              values.floatValue <=
                                                                  fiscalPayment
                                                                      .totalDueAmount
                                                                      .amount
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ) : null;
                                })}
                            </TableBody>
                        </Table>
                    )}
                    <Stack
                        direction="row"
                        width="100%"
                        alignItems="center"
                        justifyContent="flex-end"
                        marginTop="16px"
                        paddingRight="16px"
                        spacing={1}
                    >
                        <Typography variant="body700">
                            {fs("residualAmount")}
                        </Typography>
                        <FormattedAmount
                            amount={remainingAmount}
                            currency={refCurrency}
                        />
                    </Stack>
                    <Typography variant="body" color="red">
                        {remainingAmount < 0 &&
                            fs("paymentSumCannotExceedDocumentAmount")}
                    </Typography>
                    <Box marginLeft="-16px">
                        <VaporToolbar
                            contentRight={[
                                <Button variant="outlined" onClick={onCancel}>
                                    {fs("cancel")}
                                </Button>,
                                <Button
                                    variant="contained"
                                    disabled={remainingAmount < 0}
                                    type="submit"
                                >
                                    {fs("save")}
                                </Button>
                            ]}
                        />
                    </Box>
                </Spinner>
            </form>
        </FormProvider>
    );
}
