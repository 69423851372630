import { Spinner } from "@comic/precog-components";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box, Divider, Stack } from "@vapor/react-material";
import { uniq } from "lodash";
import { useEffect, useState } from "react";

import { faFileLines, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useRegisters } from "../../../core/contexts/Registers";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import CustomButton from "../../components/CustomButton";
import ErrorBanner from "../../components/ErrorBanner";
import NotFoundBanner from "../../components/NotFoundBanner";

const fsPeriods = getFormattedStringWithScope("commons.period");
const fs = getFormattedStringWithScope("views.AccountingPeriods.Registers");

export default function Registers() {
    const [hasInitialized, setHasInitialized] = useState<boolean>(true);

    const {
        get,
        error,
        registers: allRegisters,
        generate,
        cancel,
        loading,
        generating,
        canceling,
        fetchingCancelation,
        fetchingGeneration,
        year
    } = useRegisters();
    const registers = allRegisters[0];

    useEffect(() => {
        if (!hasInitialized) {
            get();
            setHasInitialized(true);
        }
    }, [hasInitialized, get]);

    useEffect(() => {
        setHasInitialized(false);
    }, [year]);
    const getLabelButton = (): string => {
        if (generating) return fs("generationInProgress");
        if (canceling) return fs("deletionInProgress");
        return fs("generate");
    };

    return (
        <Stack padding={2}>
            <Typography variant="titleSmall">{fs("VAT")}</Typography>
            <Divider orientation="horizontal" variant="fullWidth" light />
            {error ? (
                <ErrorBanner error={error} />
            ) : registers ? (
                <Stack direction="row">
                    <Stack width="100%">
                        <Typography variant="body500" marginTop={2}>
                            {fs("vatRegisters")}
                        </Typography>
                        {(registers.creatable || registers.deleted) && (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                {registers.creatable ? (
                                    <Typography variant="body">
                                        {`${uniq(
                                            registers?.creatable?.periods.map(
                                                fsPeriods
                                            )
                                        ).join(" - ")}  ${year}`}
                                    </Typography>
                                ) : (
                                    <Typography variant="body">
                                        {fs("deletionInProgress")}
                                    </Typography>
                                )}

                                <CustomButton
                                    variant="outlined"
                                    loading={fetchingGeneration || canceling}
                                    startIcon={
                                        <FontAwesomeIcon icon={faFileLines} />
                                    }
                                    onClick={() => generate()}
                                >
                                    {getLabelButton()}
                                </CustomButton>
                            </Stack>
                        )}
                        {registers.generated && (
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                {registers.generated || generating ? (
                                    <Typography variant="body">
                                        {`${uniq(
                                            registers.generated?.periods.map(
                                                fsPeriods
                                            )
                                        ).join(" - ")} ${year}`}
                                    </Typography>
                                ) : (
                                    <Typography variant="body">
                                        {fs("deletionInProgress")}
                                    </Typography>
                                )}
                                <>
                                    <Stack direction="row" gap={2}>
                                        <CustomButton
                                            color="error"
                                            onClick={cancel}
                                            loading={fetchingCancelation}
                                            startIcon={
                                                <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                />
                                            }
                                        >
                                            {fs("cancel")}
                                        </CustomButton>
                                        <CustomButton
                                            variant="contained"
                                            loading={false}
                                            startIcon={
                                                <FontAwesomeIcon
                                                    icon={faDownload}
                                                />
                                            }
                                            onClick={() =>
                                                window
                                                    .open(
                                                        registers.generated
                                                            ?.link,
                                                        "_blank"
                                                    )
                                                    ?.focus()
                                            }
                                        >
                                            {fs("download")}
                                        </CustomButton>
                                    </Stack>
                                </>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            ) : loading ? (
                <Spinner loading={loading}>
                    <Box height={10} marginTop={20}></Box>
                </Spinner>
            ) : (
                <NotFoundBanner text={fs("noRegisters")} />
            )}
        </Stack>
    );
}
