import { faFileLines, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faCircleInfo, faDownload } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box, Divider, Stack, Tooltip, useTheme } from "@vapor/react-material";
import { isNil, uniq } from "lodash";
import {
    useAvailableJournal,
    useCancelJournal,
    useGenerateJournal
} from "../../../../core/domain/AccountingJournal/queries";
import { AccountingPeriodDto } from "../../../../core/usecases/dtos/AccountingPeriodsDto";
import { TaskStatus } from "../../../../core/usecases/dtos/TaskStatus";
import getFormattedStringWithScope from "../../../../utils/getFormattedStringWithScope";
import CustomButton from "../../../components/CustomButton";
import ErrorBanner from "../../../components/ErrorBanner";

const fsPeriods = getFormattedStringWithScope("commons.period");
const fs = getFormattedStringWithScope("views.AccountingPeriods.Accounting");

interface AccountingJournalProps {
    accountingPeriod: AccountingPeriodDto;
}
export default function AccountingJournal({
    accountingPeriod
}: AccountingJournalProps) {
    const theme = useTheme();
    const accountingPeriodId = accountingPeriod.id;
    const {
        data: journal,
        isLoading: loadingAvailableJournal,
        error: errorAvailableJournal
    } = useAvailableJournal(accountingPeriodId);

    const { mutateAsync: generateJournal, isPending: generating } =
        useGenerateJournal(accountingPeriodId);

    const { mutateAsync: cancelJournal, isPending: canceling } =
        useCancelJournal(accountingPeriodId);

    const getLabelButton = (): string => {
        if (generating || isPendingGeneration)
            return fs("generationInProgress");
        if (canceling || isPendingCancel) return fs("deletionInProgress");
        return fs("generate");
    };

    const isPendingGeneration =
        journal && journal.generated?.status === TaskStatus.PENDING;
    const isPendingCancel =
        journal && journal.deleted?.status === TaskStatus.PENDING;

    return (
        <Stack padding={2}>
            <Typography variant="titleSmall">
                {fs("accountingTitle")}
            </Typography>
            <Divider orientation="horizontal" variant="fullWidth" />
            {errorAvailableJournal && (
                <ErrorBanner error={errorAvailableJournal} />
            )}

            <Stack direction="row">
                <Stack width="100%">
                    <Box marginTop={2}>
                        <Typography
                            variant="body500"
                            marginTop={2}
                            marginRight={1}
                        >
                            {fs("accountingJournal")}
                        </Typography>
                        <Tooltip title={fs("tooltip")} arrow placement="top">
                            <FontAwesomeIcon
                                icon={faCircleInfo}
                                color={theme.palette.primary.primary}
                            />
                        </Tooltip>
                    </Box>

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {journal?.creatable && (
                            <Typography variant="body">
                                {`${uniq(
                                    journal?.creatable?.periods.map(fsPeriods)
                                ).join(" - ")}  ${
                                    accountingPeriod.sequenceNumber
                                }`}
                            </Typography>
                        )}
                        {isPendingCancel && journal?.deleted && (
                            <Typography variant="body">
                                {fs("deletionInProgress")}
                            </Typography>
                        )}
                        {(loadingAvailableJournal || isPendingGeneration) && (
                            <Typography variant="body">
                                {fs("loading")}
                            </Typography>
                        )}

                        {!isNil(journal) && !loadingAvailableJournal && (
                            <Typography variant="body"></Typography>
                        )}
                        {(journal?.creatable ||
                            journal?.deleted ||
                            isPendingGeneration ||
                            !accountingPeriod.closed) && (
                            <CustomButton
                                disabled={
                                    !accountingPeriod.closed ||
                                    loadingAvailableJournal
                                }
                                variant="outlined"
                                loading={
                                    generating ||
                                    loadingAvailableJournal ||
                                    isPendingGeneration ||
                                    isPendingCancel
                                }
                                startIcon={
                                    <FontAwesomeIcon icon={faFileLines} />
                                }
                                onClick={() =>
                                    generateJournal(accountingPeriodId)
                                }
                            >
                                {getLabelButton()}
                            </CustomButton>
                        )}
                    </Stack>

                    {journal && journal.generated && !isPendingGeneration && (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {journal.generated || generating ? (
                                <Typography variant="body">
                                    {`${uniq(
                                        journal.generated?.periods.map(
                                            fsPeriods
                                        )
                                    ).join(" - ")} ${
                                        accountingPeriod.sequenceNumber
                                    }`}
                                </Typography>
                            ) : (
                                <Typography variant="body">
                                    {fs("deletionInProgress")}
                                </Typography>
                            )}
                            <>
                                <Stack direction="row" gap={2}>
                                    <CustomButton
                                        color="error"
                                        onClick={() =>
                                            cancelJournal(accountingPeriodId)
                                        }
                                        loading={canceling || isPendingCancel}
                                        startIcon={
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                            />
                                        }
                                    >
                                        {fs("cancel")}
                                    </CustomButton>
                                    <CustomButton
                                        variant="contained"
                                        startIcon={
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                            />
                                        }
                                        onClick={() =>
                                            window
                                                .open(
                                                    journal.generated?.link,
                                                    "_blank"
                                                )
                                                ?.focus()
                                        }
                                    >
                                        {fs("download")}
                                    </CustomButton>
                                </Stack>
                            </>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Stack>
    );
}
