import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { cloneDeep } from "lodash";

import { getNewAccountingLine } from "../../../utils/accountingLinesUtils";
import {
    AccountingLine,
    AccountingLinesResponse
} from "../../usecases/dtos/AccountingLinesDto";
import { useCreateAccountingLine as useCreateAccountingLineApi } from "../../usecases/useCreateAccountingLine";
import { default as useCreateBankAccountApi } from "../../usecases/useCreateBankAccount";
import { useDeleteAccountingLine as useDeleteAccountingLineApi } from "../../usecases/useDeleteAccountingLine";
import { useEditAccountingLine } from "../../usecases/useEditAccountingLine";
import { useGetAccountingLinesV2 } from "../../usecases/useGetAccountingLinesV2";
import {
    SplitAccountingLineOptions,
    useSplitAccountingLine as useSplitAccountingLineApi
} from "../../usecases/useSplitAccountingLine";
import { useDraft } from "../Draft/queries";
import { vatLinesQueryKeys } from "../VatLines/queries";

export const accountingLinesQueryKeys = createQueryKeys("accounting-lines", {
    all: null,
    get: (params?: { draftId: string }) => [params]
});

export const useAccountingLines = (params?: { draftId: string }) => {
    const { get } = useGetAccountingLinesV2();

    return useQuery({
        ...accountingLinesQueryKeys.get(params),
        enabled: !!params?.draftId,
        queryFn: () => get(params?.draftId!),
        select: (data) => data?.accountingLinesByCodeApiResList
    });
};

export const useAccountingLinesDraftStatus = (params?: { draftId: string }) => {
    const { get } = useGetAccountingLinesV2();

    return useQuery({
        ...accountingLinesQueryKeys.get(params),
        enabled: !!params?.draftId,
        queryFn: () => get(params?.draftId!),
        select: (data) => data?.draftStatus
    });
};

export const useMutateAccountingLine = (params: {
    draftId: string;
    accountingLineId: string | string[];
}) => {
    const queryClient = useQueryClient();
    const { edit } = useEditAccountingLine();

    return useMutation({
        mutationKey: ["edit-accounting-line", params],
        mutationFn: (data: Partial<AccountingLine>) =>
            edit(params.draftId, params.accountingLineId, data),
        onMutate: async (newAccountingLine) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
            );

            // Snapshot the previous value
            const previousAccountingLines = queryClient.getQueryData(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
                    .queryKey
            );

            // Optimistically update to the new value
            queryClient.setQueryData<AccountingLinesResponse>(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
                    .queryKey,
                (old) => {
                    if (old) {
                        const {
                            accountingLinesByCodeApiResList: accountingLines
                        } = old;

                        return {
                            ...old,
                            accountingLinesByCodeApiResList:
                                accountingLines.map((line) => {
                                    if (line.uuid === params.accountingLineId) {
                                        const newAccountingLineCopy =
                                            cloneDeep(newAccountingLine);
                                        if (newAccountingLineCopy.debit) {
                                            newAccountingLineCopy["credit"] = 0;
                                        }
                                        if (newAccountingLineCopy.credit) {
                                            newAccountingLineCopy["debit"] = 0;
                                        }
                                        return {
                                            ...line,
                                            ...newAccountingLineCopy
                                        };
                                    }

                                    return line;
                                })
                        };
                    }

                    return old;
                }
            );

            // Return a context object with the snapshotted value
            return { previousAccountingLines };
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (_err, _newAccountingLine, context) => {
            queryClient.setQueryData(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
                    .queryKey,
                context?.previousAccountingLines
            );
        },
        // Always refetch after error or success:
        onSettled: (_, __, variables) => {
            queryClient.invalidateQueries(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
            );
            // only invalidate vat lines if the modified field is something
            // that may affect the vat lines
            const shouldInvalidateVatLines =
                Object.keys(variables).filter((variable) =>
                    [
                        "accountCode",
                        "regimeId",
                        "regimeExtraField",
                        "vatRate",
                        "vatDestinationId",
                        "isVat",
                        "debit",
                        "credit",
                        "startPeriodDate",
                        "endPeriodDate",
                        "exemptionNatureId",
                        "exemptionNature"
                    ].includes(variable)
                ).length !== 0;
            shouldInvalidateVatLines &&
                queryClient.invalidateQueries(
                    vatLinesQueryKeys.get(params.draftId)
                );
        }
    });
};

export const useCreateAccountingLine = (params: { draftId: string }) => {
    const queryClient = useQueryClient();
    const { create } = useCreateAccountingLineApi();
    const { data: draft } = useDraft(params.draftId);

    return useMutation({
        mutationKey: ["edit-accounting-line", params],
        mutationFn: (
            data: Pick<
                AccountingLine,
                "accountCode" | "accountDesc" | "articleDesc"
            >
        ) => {
            const accountingLine = getNewAccountingLine(
                data.accountCode,
                draft?.reasonId!
            );
            return create({
                accountingLine,
                draftId: params.draftId
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
            );
            queryClient.invalidateQueries(
                vatLinesQueryKeys.get(params.draftId)
            );
        }
    });
};

export const useCreateF24AccountingLine = (params: { draftId: string }) => {
    const queryClient = useQueryClient();
    const { create } = useCreateAccountingLineApi();

    return useMutation({
        mutationKey: ["edit-accounting-line", params],
        mutationFn: (
            data: Pick<
                AccountingLine,
                "accountCode" | "tributeCode" | "tributeYear"
            >
        ) => {
            return create({
                accountingLine: data,
                draftId: params.draftId
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
            );
        }
    });
};

export const useDeleteAccountingLineField = (params: {
    draftId: string;
    accountingLineId: string | string[];
}) => {
    const queryClient = useQueryClient();
    const { deleteFields } = useEditAccountingLine();

    return useMutation({
        mutationKey: ["edit-accounting-line", params],
        mutationFn: (data: (keyof AccountingLine)[]) =>
            deleteFields(params.draftId, params.accountingLineId, data),
        onSuccess: () => {
            queryClient.invalidateQueries(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
            );
            queryClient.invalidateQueries(
                vatLinesQueryKeys.get(params.draftId)
            );
        }
    });
};

export const useSplitAccountingLine = (
    params: Pick<SplitAccountingLineOptions, "draftId">
) => {
    const queryClient = useQueryClient();
    const { split } = useSplitAccountingLineApi();

    return useMutation({
        mutationKey: ["split-accounting-line", params],
        mutationFn: (data: Omit<SplitAccountingLineOptions, "draftId">) =>
            split({
                draftId: params.draftId,
                ...data
            }),
        onSettled: () => {
            queryClient.invalidateQueries(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
            );
        }
    });
};

export const useDeleteAccountingLine = (params: {
    draftId: string;
    accountingLineId: string;
}) => {
    const queryClient = useQueryClient();
    const { deleteAccountingLine } = useDeleteAccountingLineApi();

    return useMutation({
        mutationKey: ["split-accounting-line", params],
        mutationFn: () =>
            deleteAccountingLine(params.draftId, params.accountingLineId),
        onMutate: async () => {
            await queryClient.cancelQueries(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
            );

            const previousAccountingLines = queryClient.getQueryData(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
                    .queryKey
            );

            queryClient.setQueryData<AccountingLinesResponse>(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
                    .queryKey,
                (old) => {
                    if (old) {
                        const {
                            accountingLinesByCodeApiResList: accountingLines
                        } = old;

                        return {
                            ...old,
                            accountingLinesByCodeApiResList:
                                accountingLines.filter(
                                    (line) =>
                                        line.uuid !== params.accountingLineId
                                )
                        };
                    }

                    return old;
                }
            );

            return { previousAccountingLines };
        },
        onError: (_err, _newAccountingLine, context) => {
            queryClient.setQueryData(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
                    .queryKey,
                context?.previousAccountingLines
            );
        },
        onSettled: () => {
            queryClient.invalidateQueries(
                accountingLinesQueryKeys.get({ draftId: params.draftId })
            );
            queryClient.invalidateQueries(
                vatLinesQueryKeys.get(params.draftId)
            );
        }
    });
};

export const useCreateBankAccount = (draftId: string) => {
    const queryClient = useQueryClient();
    const { create: createBankAccount } = useCreateBankAccountApi();
    const { create: createAccountinLine } = useCreateAccountingLineApi();

    return useMutation({
        mutationKey: ["create-bank-account"],
        mutationFn: async (id: string) => {
            const bankAccount = await createBankAccount(id);

            await createAccountinLine({
                accountingLine: {
                    accountCode: bankAccount.code
                },
                draftId
            });
        },
        onSettled: () => {
            queryClient.invalidateQueries(
                accountingLinesQueryKeys.get({ draftId })
            );
        }
    });
};
