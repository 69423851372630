import { Spinner } from "@comic/precog-components";
import { OneBoxPage, useCurrentWorkspace } from "@drift/oneplatfront";
import { Box } from "@vapor/react-material";
import isNil from "lodash/isNil";

import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import ErrorBanner from "../../components/ErrorBanner";
import AccountingPeriodsNavigation from "./AccountingPeriodsNavigation";
import { useAccountingPeriods } from "../../../core/domain/AccountingPeriods/queries";

const fs = getFormattedStringWithScope("views.AccountingPeriods");

export default function AccountingPeriods() {
    const { workspace: customer } = useCurrentWorkspace(true);

    const {
        data: accountingPeriods,
        isLoading,
        isError,
        error
    } = useAccountingPeriods();

    const errorTitle = fs("errorFetchingAccountingPeriods");

    return (
        <OneBoxPage title={fs("title")} subtitle={customer?.description}>
            <Spinner loading={!customer || isLoading}>
                {isNil(accountingPeriods) ? (
                    isError ? (
                        <ErrorBanner title={errorTitle} error={error} />
                    ) : null
                ) : (
                    <Box padding="24px">
                        <AccountingPeriodsNavigation
                            accountingPeriods={accountingPeriods}
                        />
                    </Box>
                )}
            </Spinner>
        </OneBoxPage>
    );
}
