import { VaporToolbar } from "@vapor/react-custom";
import { Button } from "@vapor/react-material";
import { compact, isNil } from "lodash";

import { useDraft } from "../../../core/domain/Draft/queries";
import { DraftAccountingStatus } from "../../../utils/appEnums";
import getFormattedStringWithScope from "../../../utils/getFormattedStringWithScope";
import { ConfirmDraftButton } from "../Accounting/ConfirmDraftButton";
import { DeleteDraftButton } from "../Accounting/DeleteDraftButton";
import { LastDraftUpdate } from "../Accounting/LastDraftUpdate";
import CustomButton from "../CustomButton";

interface AccountToolbarProps {
    draftId: string;
    onClose?: () => void;
    onDelete?: () => void;
    onAccount: () => void;
    onModify?: () => void;
    isAccounted?: boolean;
    isLoadingStartModification?: boolean;
}

const fs = getFormattedStringWithScope("components.AccountToolbar");

export default function AccountToolbar({
    draftId,
    onAccount,
    onClose,
    onDelete,
    onModify,
    isLoadingStartModification = false
}: AccountToolbarProps) {
    const { data: draft } = useDraft(draftId);

    const isAccounted =
        !isNil(draft) && draft.status === DraftAccountingStatus.CONFIRMED;

    const handleDelete = async () => {
        onDelete && onDelete();
    };

    return (
        <>
            <VaporToolbar
                contentLeft={
                    onClose && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={onClose}
                        >
                            {fs("close")}
                        </Button>
                    )
                }
                contentRight={compact([
                    <LastDraftUpdate draftId={draftId} />,
                    <DeleteDraftButton
                        draftId={draftId}
                        onDeleteSuccess={handleDelete}
                    />,
                    <ConfirmDraftButton
                        draftId={draftId}
                        onConfirmSuccess={onAccount}
                    />,
                    isAccounted ? (
                        <CustomButton
                            variant="contained"
                            loading={isLoadingStartModification}
                            onClick={onModify}
                        >
                            {fs("modify")}
                        </CustomButton>
                    ) : null
                ])}
            />
        </>
    );
}
