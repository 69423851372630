import {
    CslType,
    MovementsType,
    PaymentType,
    PeriodType,
    RegimeExtraField,
    ShellCompany,
    Ventilations
} from "../utils/appEnums";
import { TaxRelief, TaxReliefType } from "../utils/assetUtils";
import flattenObject from "../utils/flattenObject";

export default flattenObject({
    notifications: {
        AccountingSetup: {
            NewRegistration: {
                success: { title: "Contabilizzazione avvenuta correttamente" }
            }
        },
        AllocateNetIncome: {
            success: {
                title: "Destina utile",
                content: "Richiesta presa in carico correttamente"
            },
            error: "Si è verificato un errore: richiesta non effettuata"
        },
        AssetRevaluation: {
            error: "Errore nella rivalutazione"
        },
        CheckDepreciation: {
            error: "Errore nel recupero dello stato degli ammortamenti"
        },
        CloseSettlement: {
            success: {
                title: "Consolida:",
                content:
                    "Richiesta di consolidamento presa in carico correttamente"
            },
            error: "Si è verificato un errore: chiusura del periodo non effettuata"
        },
        CreateDraft: {
            error: "Errore nella creazione della nuova registrazione"
        },
        CreatePayments: {
            success: {
                title: "Fattura pagata parzialmente.",
                content: "Residuo:"
            }
        },
        DeleteAccountingLine: {
            error: "Errore nell'eliminazione"
        },
        DeleteDraft: {
            success: "Registrazione eliminata con successo",
            error: "Si è verificato un errore: Registrazione non eliminata"
        },
        DeleteAssetGroup: {
            success: "Raggruppamento annullato con successo",
            error: "Si è verificato un errore: Raggruppamento non annullato"
        },
        DeleteFiscalPayment: {
            success: "Pagamento fiscale disassociato con successo"
        },
        DuplicateAccount: {
            success: "Conto duplicato con successo",
            error: "Si è verificato un errore: Conto non duplicato"
        },
        CreateAccountingPeriod: {
            error: "Errore nella creazione del nuovo esercizio"
        },
        GetAccountingPeriods: {
            error: "Errore nel recupero degli esercizi contabili"
        },
        GetAccountingPeriodDeferrals: {
            error: "Errore nel recupero dei risconti"
        },
        GetAccountingPeriodTasks: {
            error: "Errore nel recupero delle operazioni di chiusura"
        },
        GetBalance: { error: "Errore nel recupero del bilancio" },
        GetCount: { error: "Errore nel recupero dei conteggi" },
        GetDraftFromOriginalEntry: {
            error: "Errore nel recupero del dettaglio"
        },
        GetDraft: {
            error: "Errore nel caricamento del documento"
        },
        GetClonedDraft: {
            error: "Errore nel recupero del documento"
        },
        RestoreDraft: {
            error: "Errore nel ripristino del documento"
        },
        GetHighlights: {
            error: "Errore nel recupero delle informazioni in evidenza"
        },
        GetLedgerEntries: {
            error: "Errore nel recupero dei conti"
        },
        GetOnboarding: {
            error: "Errore nel recupero dei dati dell'onboarding"
        },
        GetPagedAccounts: {
            error: "Errore nel recupero dei conti"
        },
        GetVatBusinesses: {
            error: "Errore nel recupero della liquidazione iva"
        },
        GetSettlements: {
            error: "Errore nel recupero delle attività"
        },
        GetVatSummary: {
            error: "Errore nel recupero del riepologo iva"
        },
        GetWorkspaceBusinessesFlat: {
            error: "Errore nel recupero delle attività"
        },
        CloseVatSettlement: {
            error: "Errore nella chiusura del periodo"
        },
        GetDashboardPaginated: {
            error: "Errore nel caricamento della dashboard",
            errorLoadingMore: "Errore nel caricamento della dashboard"
        },
        GetLedgerAccountedEntries: {
            error: "Errore nel caricamento dei dati",
            errorLoadingMore: "Errore nel caricamento dei dati"
        },
        GetListAssets: {
            error: "Errore nel caricamento dei dati"
        },
        GetWithholdings: {
            error: "Errore nel caricamento della lista ritenute"
        },
        PostDraftConfirm: {
            success: "Registrazione contabile presa in carico",
            error: "Si è verificato un errore: Registrazione contabile non effettuata"
        },
        OpenSettlement: {
            success: "Richiesta di riapertura presa in carico correttamente",
            error: "Si è verificato un errore: apertura del periodo non effettuata"
        },
        PatchDraft: {
            error: "Errore"
        },
        PatchAccountingLine: {
            error: "Errore nella modifica"
        },
        PostOnboarding: {
            error: "Errore nell'operazione di onboarding"
        },
        SettleAccount: {
            success:
                "La registrazione dei pagamenti è stata presa in carico. Potrai visualizzarla, a breve, nella lista dei documenti contabilizzati.",
            error: "Errore nella registrazione del pagamento"
        },
        SimulateDepreciation: {
            error: "Errore nel recupero degli ammortamenti"
        },
        GenerateDepreciation: {
            error: "Errore nella generazione degli ammortamenti",
            success: "Generazione ammortamenti presa in carico"
        },
        GenerateRegister: {
            error: "Errore nella richiesta di generazione del registro, riprova più tardi."
        },
        CancelRegister: {
            error: "Errore nella richiesta di annullamento del registro, riprova più tardi."
        },
        CancelDepreciation: {
            error: "Errore nella cancellazione degli ammortamenti",
            success: "Cancellazione ammortamenti presa in carico"
        },
        SplitAccountingLine: {
            error: "Errore nel frazionamento"
        },
        SimulationPeriod: {
            error: "Errore nella creazione della simulazione",
            success: "Simulazione andata a buon fine attendere la creazione"
        },
        SimulationReport: {
            error: "Errore nel recupero della simulazione"
        },
        ClosePeriod: {
            error: "Errore nella chiusura",
            success: "Chiusura andata a buon fine attendere la chiusura"
        },
        ReopenPeriod: {
            error: "Errore nella riapertura",
            success: "Riapertura andata a buon fine attendere l'elbaorazione"
        },
        ClosenReport: {
            error: "Errore nel recupero del report"
        },
        GetSetup: { error: "Errore nel recupero dello stato del setup" },
        PostSetup: { error: "Errore nella registrazione dell'azione" },
        GetAccountingJournal: {
            error: "Errore nella creazione del nuovo registro"
        },
        CancelAccountingJournal: {
            error: "Errore nella cancellazione del registro"
        }
    },
    commons: {
        period: {
            0: "Dicembre",
            1: "Gennaio",
            2: "Febbraio",
            3: "Marzo",
            4: "Aprile",
            5: "Maggio",
            6: "Giugno",
            7: "Luglio",
            8: "Agosto",
            9: "Settembre",
            10: "Ottobre",
            11: "Novembre",
            12: "Dicembre",
            JANUARY: "Gennaio",
            FEBRUARY: "Febbraio",
            MARCH: "Marzo",
            APRIL: "Aprile",
            MAY: "Maggio",
            JUNE: "Giugno",
            JULY: "Luglio",
            AUGUST: "Agosto",
            SEPTEMBER: "Settembre",
            OCTOBER: "Ottobre",
            NOVEMBER: "Novembre",
            DECEMBER: "Dicembre",
            ANNUAL: "Annuale",
            FIRST_QUARTER: "1° Trimestre",
            SECOND_QUARTER: "2° Trimestre",
            THIRD_QUARTER: "3° Trimestre",
            FOURTH_QUARTER: "4° Trimestre",
            ADVANCE_MONTHLY: "Acconto Iva mensile",
            VAT_ADVANCE_MONTHLY: "Acconto Iva mensile",
            ADVANCE_TRIMONTHLY: "Acconto Iva trimestrale",
            ADVANCE_QUARTERLY: "Acconto Iva trimestrale",
            VAT_ADVANCE_TRIMONTHLY: "Acconto Iva trimestrale",
            ADVANCE: "Acconto Iva"
        },
        draftStatusMessage: {
            ZERO_DEBIT: "L'addebito totale deve essere superiore a 0",
            DRAFT_ALREADY_ACCOUNTED:
                "La scrittura è già stata inviata in contabilità",
            REQUIRED_DOCUMENT_DATE:
                "Il campo Data documento non può essere vuoto",
            FAR_AHEAD_DOCUMENT_DATE:
                "La Data del documento deve essere precedente o uguale alla Data di registrazione",
            ACCOUNTING_LINES_SIZE:
                "La scrittura contabile deve avere almeno due conti.",
            REQUIRED_ACCOUNT_CODE: "Inserire conto contabile",
            BOTH_DEBIT_CREDIT:
                "Ogni conto contabile può essere movimentato o in dare o in avere.",
            FAR_AHEAD_START_PERIOD:
                "La data di Fine competenza non può essere vuota o antecedente a quella di Inizio competenza",
            REQUIRED_REGIME:
                "Ad ogni movimento deve essere associato un Regime",
            REQUIRED_EXEMPTION_NATURE:
                "Se % iva è pari a 0, scegliere la natura di esenzione",
            REQUIRED_ARTICLE_DESCRIPTION:
                "Non è possibile definire una % aliquota in questa registrazione",
            NOT_COMPATIBLE_REASON:
                "La causale contabile non è compatibile con il tipo di documento",
            REQUIRED_CURRENCY: "Il campo Valuta non può essere vuoto",
            REQUIRED_BUSINESS: "Il campo Attività non può essere vuoto",
            UNBALANCED_ENTRY:
                "Il totale Dare deve essere uguale al totale Avere",
            REQUIRED_ASSET_GROUP_KIND:
                "Il campo Gruppo e/o il campo Specie non possono essere vuoti ",
            REQUIRED_ASSET_SUBKIND: "Il campo Sottospecie non può essere vuoto",
            REQUIRED_ASSET_AVAILABILITY_DATE:
                "Il campo Data di disponibilità in anagrafica cespiti non può essere vuoto",
            REQUIRED_ASSET_TAX_DEPRECIATION:
                "Il campo Ammortamento fiscale non può essere vuoto",
            REQUIRED_ASSET_IRAP: "Il campo % IRAP deve essere compilato",
            UNNECESSARY_ASSET_IRAP: "Il campo % IRAP non deve essere compilato",
            REQUIRED_ASSET_STATUTORY_DEPRECIATION:
                "Il campo % di Ammortamento non può essere vuoto",
            REQUIRED_REVERSE_CHARGE_LINE:
                'Inserire un N6 o disattivare il flag "Fattura con reverse charge"',
            DRAFT_INTERMEDIATE_STATUS:
                "La registrazione è in stato intermedio, per favore attendi"
        },
        deferralTypes: {
            DEFERRED_EXPENSES: "Risconti attivi",
            DEFERRED_REVENUES: "Risconti passivi"
        },
        vatCollectability: {
            SPLIT_PAYMENT: "Split payment",
            DEFERRED: "IVA differita",
            IMMEDIATE: "Immediata"
        },
        VatRate: {
            "0.0": "0%",
            "4.0": "4%",
            "5.0": "5%",
            "10.0": "10%",
            "22.0": "22%"
        },
        VatEnum: {
            IVA4: "4%",
            IVA5: "5%",
            IVA22: "22%",
            IVA2: "2%",
            IVA0: "0%",
            ESC000: "0%",
            IVA10: "10%",
            NIM026: "0%",
            NIM027: "0%",
            NIM024: "0%",
            NIM025: "0%",
            NIM028: "0%",
            NIM029: "0%",
            ESE000: "0%",
            ESE001: "0%",
            ESE002: "0%",
            ESE003: "0%",
            ESE004: "0%",
            ESE005: "0%",
            ESE006: "0%",
            NIM030: "0%",
            NONE: "0%",
            ESC009: "0%",
            ESC008: "0%",
            ESC007: "0%",
            ESC006: "0%",
            ESC005: "0%",
            NA: "0%",
            ESC011: "0%",
            ESC010: "0%",
            NIM000: "0%",
            NIM001: "0%",
            FCI001: "0%",
            NIM004: "0%",
            FCI000: "0%",
            NIM005: "0%",
            FCI003: "0%",
            NIM002: "0%",
            FCI002: "0%",
            NIM003: "0%",
            NIM008: "0%",
            NIM009: "0%",
            NIM006: "0%",
            NIM007: "0%",
            FCI009: "0%",
            FCI008: "0%",
            INTEREST1: "1%",
            FCI005: "0%",
            FCI004: "0%",
            FCI007: "0%",
            FCI006: "0%",
            ESC004: "0%",
            ESC003: "0%",
            ESC002: "0%",
            ESC001: "0%",
            PLAFOND: "0%",
            NIM011: "0%",
            NIM012: "0%",
            NIM010: "0%",
            NIM015: "0%",
            NIM016: "0%",
            NIM013: "0%",
            NIM014: "0%",
            NIM019: "0%",
            FCI010: "0%",
            NIM017: "0%",
            NIM018: "0%",
            OSS000: "0%",
            FORF50: "0%",
            ESE007: "50%",
            ESE008: "0%",
            IMP003: "0%",
            IMP002: "0%",
            IMP001: "0%",
            IMP000: "0%",
            NVI000: "0%",
            IMP007: "0%",
            AUTOINVOICE: "0%",
            IMP006: "0%",
            IMP005: "0%",
            IMP004: "0%",
            NIM022: "0%",
            NIM023: "0%",
            NIM020: "0%",
            NIM021: "0%"
        },
        Reason: {
            DCE1: "Dismissione cespite"
        }
    },
    components: {
        Accounting: {
            tabs: {
                ACCOUNTABILITY: "Contabilità",
                ASSET: "Cespiti",
                WITHHOLDING: "Ritenute",
                USEDGOODS: "Beni Usati"
            },
            AccountingLinesTable: {
                account: "Conto contabile",
                vatRegime: "Regime IVA",
                vatRate: "Aliquota IVA",
                vatDestination: "Destinazione IVA",
                give: "Dare",
                take: "Avere",
                competencePeriod: "Inizio e Fine competenza",
                addAccount: "Conto contabile",
                cancelAddAccount: "Annulla inserimento",
                addBankAccount: "Banca",
                previousBalance: "Saldo precedente",
                AddAccountingLineButton: {
                    addAccountingLine: "Aggiungi imponibile"
                },
                AddAccountButton: {
                    cancelAddAccount: "Annulla inserimento",
                    account: "Conto contabile"
                },
                AddBankAccountButton: {
                    cancelAddBank: "Annulla inserimento",
                    bank: "Banca"
                },
                AddPaymentButton: {
                    addPayment: "Registra pagamento",
                    payment: "Pagamento",
                    creditNote: "Compensa con nota di credito",
                    advance: "Compensa con acconto"
                },
                AccountDetailDrawer: {
                    title: "Dettaglio conto",
                    ledgerNotFound: "Nessun partitario trovato",
                    ledger: "Partitario",
                    articles: "Articoli",
                    accountRegistry: "Anagrafica conto",
                    AccountRegistry: {
                        accountDesc: "Descrizione conto",
                        accountType: "Tipo conto",
                        nonDeductibility: "Indetraibilità",
                        nonDeductibleRate: "IRPEF/IRES:",
                        nonDeductibleIrapRate: "IRAP metodo fiscale:",
                        nonDeductibleStatutoryIrapRate:
                            "IRAP: metodo civilistico",
                        vat: "IVA",
                        nonDeductibleVATRate: "% di indetraibilità:",
                        vatDestinationDesc: "Destinazione IVA:",
                        withholding: "Soggetto a ritenuta d'acconto:",
                        isAsset: "Conto immobilizzi:",
                        isAssetTrue: " SI",
                        isAssetFalse: " NO",
                        BALANCE_SHEET: "Patrimoniale",
                        INCOME_STATEMENT: "Economico"
                    },
                    Articles: {
                        moveTo: "Sposta in",
                        changeVatRegime: "Cambio regime IVA",
                        vatRate: "Aliquota",
                        exemption: "Esenzione",
                        vatDestination: "Destinazione IVA",
                        save: "Salva",
                        selectAll: "Seleziona tutti"
                    }
                },
                ArticleActions: {
                    moveArticleIn: "Sposta articolo in",
                    noAvailableAccount: "Nessun conto contabile disponibile",
                    otherActions: "Altre azioni",
                    splitArticle: "Fraziona articolo"
                },
                SelectVatRate: {
                    selectNature: "Seleziona natura esenzione",
                    handleReverseCharge: "Gestisci il reverse charge",
                    reverseChargeRate: "Aliquota reverse charge"
                },
                SelectVatRegime: {
                    [RegimeExtraField.COMPENSATION_PERCENTAGE]:
                        "% di compensazione",
                    [RegimeExtraField.FORFAIT_PERCENTAGE]:
                        "% di forfettizzazione",
                    [RegimeExtraField.SECOND_VAT_RATE]: "2a Aliqu. IVA",
                    [RegimeExtraField.UNDEDUCTIBILITY_PRECENTAGE]:
                        "% indetraibilità",
                    [RegimeExtraField.RETURN_PERCENTAGE]: "% reso",
                    [RegimeExtraField.USED_GOOD_RATE]: "Aliquota iva Bene Usato"
                },
                SplitArticleDialog: {
                    splitArticle: "Fraziona articoli",
                    caption:
                        "Puoi separare gli articoli se hanno regimi, destinazioni diverse o spostarne una parte in altri conti",
                    of: "di",
                    amount: "Importo",
                    vatRegime: "Regime IVA",
                    vatRate: "Aliquota",
                    cancel: "Annulla",
                    account: "Conto contabile",
                    vatDestination: "Destinazione",
                    residual: "Residuo:",
                    totalMustBeTheSame:
                        "Il totale delle frazioni deve essere uguale al totale dell'articolo originale",
                    save: "Salva"
                }
            },
            F24AccountingLinesTable: {
                tributeCode: "Codice tributo",
                accountCode: "Conto contabile",
                tributeYear: "Anno",
                give: "Dare",
                take: "Avere",
                addTribute: "Tributo",
                cancelAddTribute: "Annulla inserimento"
            },
            AllocateGainAccountingLinesTable: {
                account: "Conto contabile",
                give: "Dare",
                take: "Avere"
            },
            CloseClonedDialog: {
                title: "Modifica documento",
                closingWithoutSaving: "Stai uscendo senza salvare.",
                modificationWillBeLostUnlessSaved:
                    "Le modifiche che hai apportato andranno perdute se non le salvi.",
                doNotSave: "Non salvare",
                confirmModifications: "Apporta modifiche"
            },
            CA21: {
                createAccountFrom: "Crea conto da",
                customer: "Cliente",
                supplier: "Fornitore",
                newBank: "Nuova banca",
                cancelAdding: "Annulla inserimento",
                account: "Conto contabile",
                give: "Dare",
                take: "Avere",
                vatCompetencePeriod: "Inizio e fine competenza",
                Header: {
                    reason: "Causale",
                    registrationDate: "Data registrazione",
                    selectBusiness: "Seleziona attività",
                    commencementDate:
                        "La data corrisponde  all'inizio gestione contabilità"
                }
            },
            ConfirmDraftButton: {
                confirmDraft: "Contabilizza",
                saveModifications: "Apporta modifiche"
            },
            DeleteDraftButton: {
                delete: "Elimina"
            },
            DeletePaymentDialog: {
                deletePayment: "Elimina pagamento",
                warning: "Attenzione",
                body: "procedendo con l'operazione, non effettuerai la registrazione del pagamento di questo documento.",
                cancel: "Annulla",
                delete: "Elimina"
            },
            LinkInvoices: {
                associateSuspendedInvoicesTo:
                    "Associa pagamento a fatture sospese",
                associateSuspendedCreditNotesTo:
                    "Associa pagamento a note credito",
                reason: "Causale",
                docNumber: "N. documento",
                docDate: "Data documento",
                amount: "Importo",
                residual: "Residuo",
                collection: "Incasso",
                payment: "Pagamento"
            },
            Payments: {
                payments: "Pagamenti",
                account: "Conto contabile",
                give: "Dare",
                take: "Avere",
                date: "Data",
                previousBalance: "Saldo precedente",
                tableHead: {
                    [PaymentType.CREDIT_NOTE]: "Note di credito",
                    [PaymentType.PAYMENT]: "Acconti"
                },
                [PaymentType.CREDIT_NOTE]: "nota di credito",
                [PaymentType.PAYMENT]: "acconto",
                amount: "Importo",
                docDate: "Data documento",
                description: "Descrizione",
                cancel: "Annulla",
                save: "Salva"
            },
            PaymentsDrawer: {
                title: {
                    [PaymentType.CREDIT_NOTE]: "Compensa con nota di credito",
                    [PaymentType.PAYMENT]: "Compensa con acconto"
                },
                [PaymentType.CREDIT_NOTE]: "nota di credito",
                [PaymentType.PAYMENT]: "acconto",
                paymentData: "Dati del pagamento",
                of: "del",
                payments: "Pagamenti",
                compensateWithCreditNote: "Compensa con nota di credito",
                addPayment: "Aggiungi pagamento",
                residual: "Residuo",
                cancel: "Annulla",
                save: "Salva",
                amount: "Importo",
                docDate: "Data documento",
                description: "Descrizione",
                associatedAmount: "Importo associato",
                paymentSumCannotExceedDocumentAmount:
                    "La somma dei pagamenti non può essere superiore al totale del documento",
                no: {
                    [PaymentType.CREDIT_NOTE]: "Nessuna nota di credito",
                    [PaymentType.PAYMENT]: "Nessun acconto"
                },
                found: {
                    [PaymentType.CREDIT_NOTE]: "trovata",
                    [PaymentType.PAYMENT]: "trovato"
                },
                residualAmount: "Residuo:"
            },
            LastDraftUpdate: {
                draftSaved: "Bozza salvata"
            }
        },
        AccountToolbar: {
            close: "Chiudi",
            delete: "Elimina",
            update: "Aggiorna",
            account: "Contabilizza",
            modify: "Modifica",
            saveModifications: "Apporta modifiche"
        },
        AppTitle: { title: "Report" },
        BalanceTable: {
            activity: "Attività",
            passivity: "Passività",
            costs: "Costi",
            paid: "Pagati",
            notPaid: "Non pagati",
            collected: "Riscossi",
            notCollected: "Non riscossi",
            total: "Totale",
            revenues: "Ricavi",
            operatingBalance: "Saldi d'esercizio",
            totalActiveBalanceSheet: "Stato patrimoniale attivo totale",
            totalPassiveBalanceSheet: "Stato patrimoniale passivo totale",
            costsTotal: "Totale costi",
            totalRevenues: "Totale ricavi",
            operatingResult: "Risultato d'esercizio",
            balanceSheet: "Stato Patrimoniale",
            incomeStatement: "Conto Economico",
            operatingBalanceFooter: "Saldo d'esercizio",
            fiscalOperatingBalance: "Risultato d'esercizio fiscale",
            competenceOperatingBalance: "Risultato d'esercizio di competenza"
        },
        ContextualPaymentDrawer: {
            title: "Dati del pagamento",
            save: "Salva",
            cancel: "Annulla",
            tabs: {
                payment: "Dati pagamento",
                deadlines: "Scadenze"
            },
            form: {
                title: "Pagamenti",
                paymentDate: "Data di pagamento",
                amount: "Importo",
                account: "Conto contabile"
            }
        },
        CustomerSupplierInformation: {
            CUSTOMER: "Cliente",
            SUPPLIER: "Fornitore",
            vatNumber: "P. IVA",
            taxId: "C.F.",
            privateSale: "Vendita a privato"
        },
        CSL: {
            BankDialog: {
                newBank: "Nuova banca",
                cancel: "Annulla",
                save: "Salva"
            }
        },
        DateRangePicker: {
            selectStartDate: "Da data:",
            selectEndDate: "A data:"
        },
        DraftCard: {
            date: "Creata in data:"
        },
        DocumentDetail: {
            documentDetail: "Dettaglio documento",
            error: "Errore nella visualizzazione del documento",
            update: "Aggiorna",
            account: "Contabilizza",
            modificationFailed: "Le modifiche non sono andate a buon fine.",
            seeModified: " Vuoi vedere la versione modificata?",
            noDelete: "No, elimina",
            yesModify: "Sì, continua a modificare",
            close: "Chiudi"
        },
        ExemptionNatureDialog: {
            rate: "Aliquota",
            cancel: "Annulla",
            save: "Salva"
        },
        Header: {
            DocumentNumberAndDate: {
                documentNumber: "Documento n°",
                ofThe: "del"
            },
            invoiceWithReverseCharge: "Fattura con reverse charge",
            selectReverseChargeSectional: "Seleziona sezionale Reverse Charge",
            goOn: "Avanti",
            cancel: "Annulla",
            reason: "Causale",
            insertDocumentNumber: "Inserisci numero documento",
            docDateMustBeBeforeRegistrationDate:
                "La data documento deve essere minore o uguale alla data di registrazione",
            registrationDate: "Data registrazione",
            vatCompetence: "Competenza IVA",
            vatRegime: "Regime IVA",
            businessAndSectional: "Sezionale - Attività",
            business: "Attività",
            vatCollectability: "Esigibilità IVA",
            docDescription: "Descrizione documento",
            DocumentAmounts: {
                total: "Totale documento*",
                taxable: "Imponibile",
                taxes: "Imposte"
            },
            sdiId: "SDI id",
            hasReverseCharge: " Fattura con Reverse Charge",
            reverseChargeSectionalId: "Seleziona sezionale Reverse charge"
        },
        LedgerTable: {
            registrationDate: "Data registrazione",
            docNumber: "Numero documento",
            docDate: "Data documento",
            reason: "Causale",
            amount: "Importo",
            total: "Totale",
            ledgerOfTheAccount: "Partitario del conto",
            initialBalance: "Saldo iniziale",
            finalBalance: "Saldo finale"
        },
        ReverseChargeDialog: {
            doNotApplyReverseCharge:
                "Non applicare Reverse Charge per questo articolo",
            title: "Gestione Reverse charge",
            cancel: "Annulla",
            selectRate: "Seleziona aliquota integrativa",
            applyToAllOthers:
                "Applica la scelta dell'aliquota integrativa a tutti gli articoli con lo stesso codice natura",
            save: "Salva"
        },
        SelectYear: {
            year: "Anno di riferimento:"
        },
        Spinner: {
            loading: "Caricamento..."
        },
        AccountsTable: {
            code: "Codice",
            description: "Descrizione",
            type: "Tipo conto",
            iresIrap: "% ind. ires/irap",
            vat: "% indetr. iva",
            standard: "Personalizzato",
            BALANCE_SHEET: "Patrimoniale",
            INCOME_STATEMENT: "Economico",
            AccountMenu: {
                duplicateAccount: "Duplica conto"
            },
            DuplicateAccountDialog: {
                title: "Duplica conto",
                cancel: "Annulla",
                duplicate: "Duplica"
            }
        },
        AccountingLinesTable: {
            description: "Conto contabile",
            vatRegime: "Regime IVA",
            aliquote: "Aliquota IVA",
            destination: "Destinazione IVA",
            debit: "Dare",
            credit: "Avere",
            startEndPeriod: "Inizio e fine competenza",
            notFound: "Non ci sono articoli per questo conto",
            errorLoadingDetail: "Errori durante il carimento degli articoli",
            residue: "Residuo",
            previousBalance: "Saldo precedente:"
        },
        AccountsDetailDrawer: {
            accountDetail: "Dettaglio conto",
            chartAccounts: "Partitario",
            articles: "Articoli",
            accountRegistry: "Anagrafica conto",
            errorLoadingEntries: "Errore nel caricamento del partitario",
            ledgerNotFound: "Nessun partitario trovato",
            moveTo: "Sposta in",
            changeVatRegime: "Cambio regime IVA",
            rateOrExemption: "Aliquota/esenzione",
            changeVatDestination: "Cambio destinazione IVA",
            destination: "Destinazione",
            errorLoadingArticles: "Errore nel caricamento degli articoli",
            articlesNotFound: "Nessun articolo trovato",
            accountDesc: "Descrizione conto",
            accountType: "Tipo conto",
            nonDeductibility: "Indetraibilità",
            nonDeductibleRate: "IRPEF/IRES:",
            nonDeductibleIrapRate: "IRAP metodo fiscale:",
            nonDeductibleStatutoryIrapRate: "IRAP: metodo civilistico",
            vat: "IVA",
            nonDeductibleVATRate: "% di indetraibilità:",
            vatDestinationDesc: "Destinazione IVA:",
            withholding: "Soggetto a ritenuta d'acconto:",
            isAsset: "Conto immobilizzi:",
            isAssetTrue: " SI",
            isAssetFalse: " NO"
        },
        NewRegistrationButton: {
            newRegistration: "Nuova registrazione"
        },
        AccountsModal: {
            addAccount: "Aggiungi un conto contabile",
            insertAccountName: "Inserisci il nome del conto contabile",
            delete: "Annulla",
            save: "Salva"
        },
        ArticleRow: {
            ArticleActionsMenu: {
                moveArticleIn: "Sposta articolo in"
            }
        },
        FormSelectExemptionNature: {
            seeAll: "Vedi tutti"
        },
        FormCustomerSupplierLookup: {
            vatNumber: "P. IVA",
            taxId: "C.F.",
            cancel: "Annulla",
            edit: "Modifica",
            [CslType.CUSTOMER]: "Cliente",
            [CslType.SUPPLIER]: "Fornitore",
            privateSale: "Vendita a privato"
        },
        FormBankLookup: {
            bank: "Banca"
        },
        Onboarding: {
            loadingLastStep: "Attendi, stiamo creando il tuo spazio di lavoro.",
            steps: {
                commencementDate: {
                    buttons: {
                        confirm: "Conferma"
                    },
                    description:
                        "Inserisci la data di inizio gestione contabile per",
                    datePicker: { label: "Data di Inizio" },
                    title: "Inserisci la data di inizio gestione contabilità"
                },
                workspace: {
                    buttons: {
                        complete: "Completa anagrafica",
                        refresh: "Aggiorna"
                    },
                    description:
                        "Per iniziare a contabilizzare devi prima <b>completare i dati</b> in {br} queste sezioni dell' <b>anagrafica cliente</b>:{br} <ul> <li> <b> Dati generali</b> </li> <li> <b>Attività</b> </li> <li> <b>Sedi</b> </li> <li> <b> Contabilità</b> </li> </ul>{br}Poi torna su questa pagina per iniziare a lavorare sul cliente.",
                    title: "Contabilità"
                }
            },
            subtitle: "Panoramica",
            title: "Prima Nota"
        },
        ProcedureEntryDetail: {
            procedureEntryDetail: "Dettaglio documento",
            close: "Chiudi",
            Accounting: {
                accounting: "Contabilità"
            },
            error: "Errore nella visualizzazione del documento"
        },
        SetupBanner: {
            actions: {
                ignore: "No, non mostrare più",
                continue: "Continua",
                register: "Inizia ora",
                skip: "La prossima volta"
            },
            newClient: "{customer} è un nuovo cliente.",
            question:
                "Vuoi inserire una situazione contabile di partenza e recuperare fatture da Ts Digital?",
            complete: "Completa inserimento situazione contabile di partenza"
        }
    },
    views: {
        AccountingPeriods: {
            title: "Esercizi contabili",
            newAccountingPeriod: "Nuovo esercizio",
            NewAccountingPeriod: {
                noAccountingPeriods:
                    "Ciao! Non hai ancora aperto un esercizio contabile per {companyName}.",
                openNow: "Aprilo subito, basta un minuto!",
                createNewPeriod: "Crea nuovo esercizio",
                insertDataAndCreateNewPeriod:
                    "Inserisci i dati e crea il nuovo esercizio."
            },
            errorFetchingAccountingPeriods:
                "Errore nel caricamento degli esercizi contabili",
            AccountingPeriodCard: {
                status: {
                    closed: "Chiuso",
                    open: "Aperto"
                },
                startDate: "Inizio esercizio",
                endDate: "Fine esercizio"
            },
            AccountingPeriodDetail: {
                tabs: {
                    balance: "Bilancio",
                    closingOperations: "Operazioni di chiusura",
                    registers: "Situazione registri"
                }
            },
            AllocateGain: {
                title: "Destinazione risultato esercizio precedente",
                periodClosure: "Chiusura d'esercizio",
                allocateGain: "Destina utile",
                reason: "Causale",
                registrationDate: "Data di registrazione",
                accountButton: "Contabilizza",
                cancel: "Annulla",
                account: "Conto contabile",
                take: "Avere",
                give: "Dare",
                periodResult: "Risultato di esercizio",
                transfer: "Destinazione risultato di esercizio",
                addAccount: "Aggiungi conto contabile"
            },
            AccountingPeriodsTable: {
                columns: {
                    year: "Anno",
                    periodStart: "Inizio esercizio",
                    periodEnd: "Fine esercizio",
                    openOrClose: "Chiusura/Apertura"
                },
                open: "Aperto",
                closed: "Chiuso"
            },
            AccountingPeriodTasks: {
                title: "OPERAZIONI DI CHIUSURA",
                titleAllocateGain:
                    "Destinazione risultato esercizio precedente",
                tot: "tot.",
                noTaskFound: "Nessuna operazione trovata",
                accountingPeriodTask: {
                    openingNetIncome: "Saldo risultato esercizio precedente",
                    deferredRevenuesAndExpenses: "Risconti",
                    totDeferredRevenues: "tot. Risconti passivi",
                    totDeferredExpenses: "tot. Risconti attivi",
                    depreciation: "Ammortamenti",
                    accountCloseOpen: "Chiusura e riapertura dei conti",
                    period: "Periodo"
                }
            },
            DeferralsTable: {
                registrationDate: "Data registrazione",
                docDate: "Data documento",
                docNumber: "Numero Documento",
                reason: "Causale",
                account: "Conto contabile",
                counterpart: "Contropartita",
                competenceStartEnd: "Inizio/Fine competenza",
                amount: "Importo",
                daysInPeriod: "Giorni comp. esercizio",
                daysAfter: "Giorni comp. success",
                value: "Valore"
            },
            DeferredRevenuesAndExpensesOverlay: {
                deferrals: "RISCONTI",
                noData: "Nessun risconto trovato",
                totalDeferredExpenses: "Totale risconti attivi",
                totalDeferredRevenues: "Totale risconti passivi"
            },
            DepreciationOverlay: {
                depreciation: "Ammortamenti",
                depreciationDate: "Data ammortamento",
                generateDepreciation: "Genera ammortamento",
                generatingDepreciation: "Generazione in corso...",
                cancelDepreciation: "Annulla Ammortamento",
                noData: "Nessun ammortamento trovato",
                totalDepreciationValue: "Totale ammortamenti",
                year: "Anno",
                Alert: {
                    generationInProgress:
                        "La generazione dell'ammortamento è in corso. Questa Operazione potrebbe richiedere alcuni minuti. é possibile continuare a lavorare su altre attività.",
                    alertInfoError:
                        "Generazione ammortamento non andato a buon fine",
                    generateError:
                        "Generazione ammortamento non andato a buon fine",
                    cancelInProgress:
                        "L'annullamento dell'ammortamento è in corso. E' possibile continuare a lavorare su altre attività.",
                    cancelError:
                        "Annullamento ammortamento non andato a buon fine"
                }
            },
            DepreciationForm: {
                depreciation: "Ammortamento",
                actualDepreciation: "Ammortamento effettivo",
                total: "Totale:",
                suspension: "Sospensione"
            },
            CloseAndReopenAccountsOverlay: {
                title: "Chiusura e riapertura dei conti",
                generatePeriod: "Genera chiusura",
                generatePeriodPending: "Generazione chiusura in corso",
                generateSimulationPeriod: " Genera simulazione",
                openPeriod: "Annulla chiusura",
                updateSimulationPeriod: "Aggiorna simulazione",
                dateSimulation: "Simulazione generata il",
                dateOpen: "Data apertura",
                dateClose: "Data chiusura",
                totalGiving: "Totale dare",
                totalHave: "Totale avere",
                costs: "Costi",
                revenues: "Ricavi",
                profitLoss: "Utile/Perdita",
                year: "Anno",
                Alert: {
                    simulationTodo:
                        "Per procedere è necessario generare una simulazione di chiusura e riapertura dei conti",
                    simulationInProgress:
                        "La generazione della simulazione è in corso. È possibile continuare a lavorare su altre attività",
                    simulationError: "La simulazione non è andata a buon fine",
                    generationInProgress:
                        "La generazione della chiusura e riapertura dei conti è in corso. È possibile continuare a lavorare su altre attività",
                    generationError:
                        "La generazione chiusura e riapertura dei conti non è andata a buon fine",
                    reopeningInProgress:
                        "Annullamento Chiusura e riapertura dei conti in corso. È possibile continuare a lavorare su altre attività",
                    reopeningError:
                        "Annullamento Chiusura e riapertura dei conti non è andata a buon fine"
                },
                DialogAlert: {
                    title: "Chiusura contabile",
                    firstText:
                        "La chiusura e riapertura dei conti potrebbe non corrispondere alla simulazione se ci sono state nuove operazioni.",
                    secondText:
                        "Puoi aggiornare la simulazione oppure generare la chiusura e riapertura dei conti.",
                    buttonUpdate: "Aggiorna",
                    buttonClose: "Genera Chiusura"
                }
            },
            CloseAndReopenAccountsTable: {
                title: "Conti contabili",
                columns: {
                    accountingAccount: "Conto contabile",
                    accountType: "Tipo conto",
                    closingBalance: "Saldo Chiusura",
                    giveOpening: "Dare apertura",
                    haveOpening: "Avere apertura"
                },
                BALANCE_SHEET: "Patrimoniale",
                INCOME_STATEMENT: "Economico",
                summary: {
                    total: "Totale"
                }
            },
            Registers: {
                generationInProgress: "Generazione in corso...",
                deletionInProgress: "Annullamento in corso...",
                available: "Disponibile",
                creatable: "Da generare",
                generate: "Genera documento",
                cancel: "Elimina documento",
                download: "Scarica documento",
                VAT: "Iva",
                vatRegisters: "Registri IVA",
                noRegisters: "Nessun registro da generare",
                loading: "Caricamento in corso..."
            },
            Accounting: {
                accountingTitle: "Contabilità",
                accountingJournal: "Libro giornale",
                tooltip:
                    "La generazione del libro giornale è disponibile dalla chiusura del periodo contabile.",
                generate: "Genera documento",
                generationInProgress: "Generazione in corso...",
                deletionInProgress: "Annullamento in corso...",
                cancel: "Elimina documento",
                download: "Scarica documento",
                available: "Disponibile",
                creatable: "Da generare",
                loading: "Caricamento in corso..."
            }
        },
        Balance: {
            title: "BILANCIO",
            filtersTitle:
                "Imposta i filtri per visualizzare il bilancio d'esercizio",
            selectActivity: "Seleziona attività",
            year: "Anno di riferimento",
            clearFilters: "Pulisci filtri",
            search: "Cerca",
            noReportsForSearch:
                "Non sono presenti report per la ricerca effettuata!",
            viewByCompetence: "Visualizza situazione contabile per competenza",
            includeProvisional: "Includi simulazione quote di ammortamento"
        },
        AccountingSetup: {
            title: "Setup contabilità",
            activity: "Attività",
            registerPreviousSituation:
                "Registra la situazione pregressa da riportare nella nuova gestione contabile",
            previousBalance: "Registrazione saldi precedenti",
            errorDocument: "Errore nel recupero del documento",
            newRegistration: "Nuova Registrazione",
            notActivities: "Non sono presenti attività",
            suggestionAct: "Attività suggerite",
            insertStart: "Inserisci situazione contabile di partenza",
            importDraft: "Importa fatture da TS Digital",
            finishSetup: "Concludi setup",
            registrationInProgress: "Registrazione contabile presa in carico",
            close: "Chiudi",
            registrationError: "Errore: non contabilizzata",
            processing: "In elaborazione",
            draftOf: "Bozza del",
            confirmedOn: "Contabilizzata il"
        },
        Error: {
            error: "Errore",
            genericConfigError:
                "Non hai i permessi per accedere a questa applicazione."
        },
        Home: {
            title: "REPORT",
            balance: "Bilancio",
            vat: "Liquidazione e Report IVA",
            ledger: "Partitario",
            selectTypeOfReport:
                "Seleziona la tipologia di report che vuoi visualizzare"
        },
        SettleAccount: {
            title: "SALDA CONTO",
            selectActivity: "Seleziona l'attività",
            selectAccount: "Seleziona il conto di pagamento",
            registerPayment: "Registra pagamento",
            emittedDocuments: "Documenti emessi",
            receivedDocuments: "Documenti ricevuti",
            selectActivityToContinue: "Seleziona un'attività per continuare",
            noSimplifiedAccountingBusiness:
                "Non hai nessuna attività in contabilità semplificata, la funzionalità del salda conto è disattivata.",
            Table: {
                notFound: "Nessun documento trovato",
                docNumber: "Numero documento",
                docDate: "Data documento",
                registrationDate: "Data registrazione",
                customer: "Cliente",
                supplier: "Fornitore",
                reason: "Causale",
                docAmount: "Importo documento",
                residual: "Residuo",
                paymentAmount: "Importo pagamento",
                paymentDate: "Data pagamento"
            }
        },
        VAT: {
            carousel: {
                left: "Vai al periodo precedente",
                right: "Vai al periodo successivo",
                vatOnCredit: "IVA a credito",
                vatOnDebit: "IVA a debito",
                reopenPeriod: "Riapri periodo",
                periodClosureInProgress: "Chiusura periodo in corso",
                periodOpeningInProgress: "Apertura periodo in corso",
                tooltipReopenPeriod:
                    "Per riaprire il periodo è necessario annullare la stampa del Registro iva"
            }
        },
        VATSettlement: {
            title: "Liquidazione e Report IVA",
            filtersTitle:
                "Imposta i filtri per visualizzare il periodo di riferimento",
            tabs: {
                vatSettlement: "Liquidazione IVA",
                vat: "Report IVA"
            },
            overview: "Overview",
            detail: "Dettaglio",
            selectActivity: "Seleziona attività",
            goBackTo: "Torna a",
            consolidate: "Consolida",
            notFound: "Non sono presenti report per la ricerca effettuata!",
            errorLoadingEntries: "Errore nel caricamento dei dati",
            infoRecap: {
                previousPeriodVatCredit: "Credito IVA periodo precedente",
                previousPeriodVatDebit: "Debito IVA periodo precedente",
                creditAmount: "Importo a credito",
                debitAmount: "Importo a debito"
            },
            monoActivity: {
                title: "Riepilogo attività:",
                purchase: "IVA su acquisti",
                receipt: "IVA su corrispettivi",
                purchase_nondeductible: "IVA indetraibile",
                base_calc: "Dettaglio base di calcolo per la ventilazione",
                detail_receipt: "Dettaglio base di calcolo per la ventilazione",
                [Ventilations.VENTILATION_RECEIPT]: "Vendite di periodo",
                [Ventilations.VENTILATION_PURCHASE]: "Progressivi acquisti",
                sale: "IVA su vendite",
                split_payment_sale:
                    "IVA su vendite split payment a carico committente",
                iva_detraibile_regime_speciale_agricoltura:
                    "IVA detraibile regime speciale agricoltura",
                iva_detraibile_cessione_art8_art34:
                    "IVA detraibile cessione art8, art34",
                iva_detraibile_agricoltura_speciale_0200:
                    "Iva detraibile % compensazione 2%",
                iva_detraibile_agricoltura_speciale_0400:
                    "Iva detraibile % compensazione 4%",
                iva_detraibile_agricoltura_speciale_0600:
                    "Iva detraibile % compensazione 6%",
                iva_detraibile_agricoltura_speciale_0640:
                    "Iva detraibile % compensazione 6,4%",
                iva_detraibile_agricoltura_speciale_0730:
                    "Iva detraibile % compensazione 7,3%",
                iva_detraibile_agricoltura_speciale_0750:
                    "Iva detraibile % compensazione 7,5%",
                iva_detraibile_agricoltura_speciale_0830:
                    "Iva detraibile % compensazione 8,3%",
                iva_detraibile_agricoltura_speciale_0850:
                    "Iva detraibile % compensazione 8,5%",
                iva_detraibile_agricoltura_speciale_0880:
                    "Iva detraibile % compensazione 8,8%",
                iva_detraibile_agricoltura_speciale_0950:
                    "Iva detraibile % compensazione 9,5%",
                iva_detraibile_agricoltura_speciale_1000:
                    "Iva detraibile % compensazione 10%",
                iva_detraibile_agricoltura_speciale_1230:
                    "Iva detraibile % compensazione 12,3%",
                editoria: "IVA regime speciale editoria",
                suspended_sale: "Fatture vendita sospese registrate",
                suspended_sale_paid: "Fatture vendita sospese pagate",
                suspended_purchase: "Fatture acquisto sospese registrate",
                suspended_purchase_paid: "Fatture acquisto sospese pagate",
                iva_indetraibile_prorata: "Iva indetraibile per pro-rata",
                iva_detraibile_forfetaria_art_34_bis:
                    "IVA det.forfetaria art 34 bis",
                iva_indetraibile: "IVA su acquisti idetraibile",
                iva_detraibile_forfetaria_art_5: "DETR.FORF.art.5 L413/91",
                enooleoagri_deducibile_sale: "DETR.FORF.art.5 L413/91",
                enooleoagri_non_deducibile_purchase: "IVA su acquisti",
                nondeductible: "indetraibile",
                table: {
                    description: "Descrizione",
                    vatRateOrExemption: "Aliquota/Esenzione",
                    vatDescription: "Descrizione aliquota",
                    vatRate: "Aliquota",
                    taxable: "Imponibile",
                    tax: "Imposta",
                    totalAmount: "Importo totale",
                    taxableAmount: "Corrispettivo lordo per aliquota",
                    startReceipt: "% di riparto",
                    nonDeductibility: "Indetraibilità",
                    total: "Totale"
                }
            },
            multiActivity: {
                table: {
                    all: "Tutti",
                    activity: "Attività",
                    creditAmount: "Importo a credito",
                    debitAmount: "Importo a debito"
                }
            },
            vatEntries: {
                summary: "Riepilogo attività:",
                table: {
                    empty: "Non sono presenti report per la ricerca effettuata!",
                    error: "Errore nel caricamento dei dati",
                    reason: "Causale",
                    registrationDate: "Data registrazione",
                    docNumber: "N. documento",
                    documentDate: "Data documento",
                    vatCollectability: "Esigibilità IVA",
                    vatCollectabilityType: {
                        SPLIT_PAYMENT: "Split payment",
                        DEFERRED: "IVA differita",
                        IMMEDIATE: "Immediata"
                    },
                    taxable: "Imponibile",
                    tax: "Imposta"
                }
            }
        },
        VATSettlementDetail: {
            title: "LIQUIDAZIONE IVA"
        },
        VATReport: {
            vatEntries: {
                table: {
                    empty: "Non sono presenti report per la ricerca effettuata!",
                    error: "Errore nel caricamento dei dati",
                    reason: "Tipo documento",
                    type: "Tipologia IVA",
                    registrationDate: "Data registrazione",
                    docNumber: "N. documento",
                    documentDate: "Data documento",
                    counterpart: "Cliente/Fornitore",
                    counterpartId: "P.IVA/C.F",
                    vatRate: "Aliquota",
                    taxable: "Imponibile",
                    tax: "Imposta",
                    total: "Totale"
                },
                filter: {
                    reportType: {
                        all: "Tutti i report",
                        sale: "Report vendite",
                        purchase: "Report acquisti",
                        receipt: "Report corrispettivi"
                    },
                    sectional: {
                        all: "Tutti i sezionali",
                        sectional: "Sezionale"
                    }
                }
            }
        },
        Ledger: {
            title: "Partitario",
            filtersTitle: "Imposta i filtri per visualizzare il partitario",
            selectAccount: "Seleziona conto",
            clearFilters: "Pulisci filtri",
            search: "Cerca",
            errorLoadingEntries: "Errore nel caricamento del partitario",
            ledgerNotFound: "Nessun partitario trovato"
        },
        ChartAccounts: {
            title: "Piano dei conti",
            selectAccount: "Cerca un conto",
            notFound: "Non sono presenti conti",
            errorLoadingAccounts: "Errore nel recupero dei conti",
            selectCustomerToView:
                "Seleziona un'azienda per visualizzare il piano dei conti"
        },
        Dashboard: {
            title: "Prima nota",
            subtitle: "Dashboard",
            tabs: {
                toBeAccounted: "Da contabilizzare",
                accounted: "Contabilizzati"
            },
            notFound:
                "La tua ricerca non ha prodotto nessun risultato. Prova a impostare filtri differenti o ad annullare tutti i filtri.",
            errorLoadingDashboard: "Errore nel caricamento dei dati",
            settleAccount: "Salda conto",
            assetsList: "Lista cespiti",
            withholdings: "Ritenute",
            usedGoods: "Beni usati",
            toBeAccounted: {
                documentWith: "Documenti con",
                otherFilters: "Altri filtri",
                documentNumber: "Numero documento",
                selectYear: "Seleziona anno",
                witholdings: "Ritenute",
                assets: "Cespiti",
                intrastat: "Intrastat",
                competence: "Periodo di competenza",
                reverseCharge: "Reverse charge",
                filters: {
                    documentWith: "Documenti con",
                    RECEIPT: "Corrispettivi",
                    DOCUMENT: "Documenti",
                    SALE: "Vendita",
                    PURCHASE: "Acquisto",
                    BANK_TRANSACTION: "Movimenti bancari",
                    FINANCIAL_TRANSACTION: "Movimenti finanziari",
                    OTHER_TRANSACTION: "Altri movimenti"
                },
                dialogDelete: {
                    title: "Elimina documento",
                    manual: "Confermando, il documento sarà eliminato.",
                    accountedManual:
                        "Confermando, il documento sarà eliminato.",
                    xml: "Confermando, il documento tornerà in lista da contabilizzare.",
                    button: {
                        confirm: "Conferma"
                    }
                },
                table: {
                    goDetail: " Vai al dettaglio",
                    accountingBtn: "Contabilizza",
                    notFound: "Non ci sono conti contabili",
                    status: "Stato",
                    reason: "Causale contabile",
                    customerOrSupplier: "Cliente/Fornitore",
                    docNumber: "Numero documento",
                    docDate: "Data documento",
                    sectional: "Sezionale/Attività",
                    amount: "Importo",
                    account: "Contabilizza",
                    accountingInProgress: "Contabilizzazione presa in carico",
                    youHaveANewCustomer: "Hai un nuovo cliente",
                    youHaveANewSupplier: "Hai un nuovo fornitore",
                    vatNumber: "P.IVA",
                    accountingProcessNotSucceeded:
                        "Registrazione contabile non andata a buon fine.",
                    ReasonCell: {
                        fixedAsset: "Cespiti",
                        performanceReason: "Causale prestazione",
                        cuReason: "Causale cu",
                        integrationTax: "Contributo integrativo (cpa)",
                        withholdingPercentage: "% ritenuta"
                    }
                }
            },
            accountedFor: {
                filters: {
                    documentWith: "Documenti con",
                    otherFilters: "Altri filtri",
                    documentNumber: "Numero documento",
                    selectYear: "Seleziona anno",
                    witholdings: "Ritenute",
                    clearFilters: "Pulisci filtri",
                    assets: "Cespiti",
                    intrastat: "Intrastat",
                    errorDate:
                        "L'intervallo date deve essere inferiore ad 1 anno"
                },
                table: {
                    postingDate: "Data registrazione",
                    reception: "Ricezione",
                    customerOrSupplier: "Cliente/Fornitore",
                    reason: "Causale contabile",
                    docDate: "Data documento",
                    docNum: "Numero documento",
                    amount: "Importo"
                }
            }
        },
        Detail: {
            title: "Prima nota",
            backToList: "Torna alla lista",
            subtitle: "Dettaglio",
            toolbar: {
                of: "del",
                accountingBtn: "Contabilizza",
                deleteBtn: "Elimina",
                dialog: {
                    title: "Elimina",
                    question: "Vuoi eliminare la seguente fattura?",
                    cancel: "Annulla"
                }
            },
            assets: {
                tooltipHasAdditionalCharge:
                    "Almeno un articolo tra quelli raggruppati non deve essere taggato come spesa accessoria",
                snackBarIncremental: {
                    bold: "Spesa incrementativa:",
                    description:
                        "Apri il pannello per selezionare il cespite a cui associare la spesa. "
                },
                buttons: {
                    newAssetGroup: "Nuovo cespite da selezione",
                    save: "Salva",
                    confirm: "Conferma",
                    cancel: "Chiudi"
                },
                alert: { noAssets: "Nessun cespite presente" },
                drawer: {
                    title: "Dati cespite",
                    titleSearch: "Seleziona cespite esistente",
                    suggestions: "Suggerite",
                    otherSubkinds: "Altre sottospecie",
                    buttons: {
                        cancelGrouping: "Annulla raggruppamento"
                    },
                    tabs: {
                        registry: "Anagrafica",
                        depreciation: "Ammortamento"
                    },
                    registry: {
                        paragraphIdentifyingData: "Dati identificativi",
                        paragraphAvailabilityDeductibility:
                            "Disponibilità e deducibilità",
                        group: "Gruppo",
                        species: "Specie",
                        subspecies: "Sottospecie",
                        materialGood: "Bene materiale",
                        intangibleGood: "Bene immateriale",
                        new: "Nuovo",
                        used: "Usato",
                        description: "Descrizione aggiuntiva",
                        placeholderDescription: "Aggiungi la descrizione",
                        placeholderNoDescription: "Nessuna descrizione",
                        assetName: "Nome cespite",
                        value: "Valore",
                        additionalCharge: "Spese accessorie",
                        nonDeductibleAmount: "IVA indetraibile",
                        codeId: "Cod. identificativo",
                        activation: "Attivazione",
                        deactivation: "Disattivazione",
                        availability: "Disponibilità",
                        reducedDeductibility: "Deducibilità ridotta",
                        deductibilityGoods: {
                            NONE: "Indeducibilità al 100%",
                            HALF: "Indeducibilità al 50%",
                            BUSINESS_VEHICLES: "Autoveicoli aziendali",
                            BUSINESS_MOTORCYCLES: "Motocicli aziendali",
                            BUSINESS_MOPEDS: "Ciclomotori aziendali",
                            REPRESENTATIVES_VEHICLES:
                                "Motocicli rappresentanti",
                            REPRESENTATIVES_MOTORCYCLES:
                                "Autoveicoli rappresentanti",
                            REPRESENTATIVES_MOPEDS:
                                "Ciclomotori rappresentanti",
                            BUILDING_AND_LAND: "Fabbricato con terreno",
                            FIXED_TELEPHONY: "Telefonia fissa 80%",
                            MOBILE_TELEPHONY: "Telefonia mobile 80%"
                        },
                        usageAllowance: "Uso promiscuo",
                        landValue: "Valore del terreno",
                        dependent: "Dipendente",
                        administrator: "Amministratore",
                        yes: "Si",
                        no: "No",
                        assignmentDate: "Data assegnazione",
                        revocationDate: "Data revoca",
                        hasIsa: "Soggetto a ISA",
                        noShellCompany: "Nessuna società",
                        shellCompany: "Società di comodo",
                        shellCompanies: {
                            [ShellCompany.BUILDINGS_AND_OTHER_ASSETS]:
                                "Immobili ed altri beni (terreni e navi)",
                            [ShellCompany.OFFICE_BUILDINGS]:
                                "Immobili uso ufficio (A10)",
                            [ShellCompany.RESIDENTIAL_BUILDINGS]:
                                "Immobili uso abitativo (A1 - A9)",
                            [ShellCompany.OTHER_FIXED_ASSETS]:
                                "Altre immobilizzazioni (materiali e immateriali)",
                            [ShellCompany.BUILDINGS_AND_OTHER_ASSETS_SMALL_MUNICIPALITIES]:
                                "Immobili ed altri beni (terreni e navi) situati in comuni con meno di 1000 abitanti"
                        }
                    },
                    depreciation: {
                        paragraphPercentage: "Ammortamento fiscale",
                        paragraphOthers: "Altri aspetti fiscali",
                        paragraphStatutoryDepreciation:
                            "Ammortamento civilistico",
                        paragraphOtherCivil: "Altri aspetti civilistici",
                        paragraphIRAP: "Ammortamento IRAP",
                        paragraphTaxBreaks: "Agevolazioni",
                        resetTaxBreaks: "Ripristina agevolazioni",
                        percentIRAP: "Percentuale IRAP",
                        depreciationCriteria: "Altri criteri di ammortamento",
                        ORDINARY: "Di legge",
                        MANUAL: "Manuale",
                        DYNAMIC: "Dinamica",
                        sellingDepreciation: "Ammortamento in vendita",
                        reportInDays: "Ragguaglia in giorni",
                        reportInDaysTooltip:
                            "Riproporziona l'ammortamento in giorni solo per i movimenti che si riferiscono all' esercizio contabile dell' anno di costituzione e di cessazione dell'azienda.",
                        decreasingMethod: "Metodo decrescente / Americano",
                        periodOfUse: "Periodo d'utilizzo",
                        taxCriteria: "Come da criteri fiscali",
                        notTaxBreaks: "Nessuna agevolazione",
                        taxBreaks: "Agevolazioni fiscali",
                        taxBreakType: "Tipo agevolazioni",
                        taxBreakDate: "Data agevolazione",
                        taxBreakValue: "Credito di imposta",
                        fixedPercentage: "Percentuale fissa",
                        taxReliefs: {
                            [TaxRelief.BALANCE_LAW_2016]: "L. di Bilancio 2016",
                            [TaxRelief.BALANCE_LAW_2017]: "L. di Bilancio 2017",
                            [TaxRelief.BALANCE_LAW_2018]: "L. di Bilancio 2018",
                            [TaxRelief.BALANCE_LAW_2019]: "L. di Bilancio 2019",
                            [TaxRelief.GROWTH_DECREE_2019]: "D. Crescita 2019",
                            [TaxRelief.BALANCE_LAW_2020]: "L. di Bilancio 2020",
                            [TaxRelief.STABILITY_LAW_2021_BIS]:
                                "L. di Stabilità 2021 (dal 01/01/2022)",
                            [TaxRelief.STABILITY_LAW_2021]:
                                "L. di Stabilità 2021",
                            [TaxRelief.STABILITY_LAW_2022]:
                                "L. di Stabilità 2021 (dal 01/01/2022)",
                            [TaxReliefType.BASE]: "Base",
                            [TaxReliefType.BASE4]: "Base 4.0",
                            [TaxReliefType.SW4]: "Software 4.0",
                            [TaxReliefType.BASE_SMARTWORKING]:
                                "Base Smartworking",
                            [TaxReliefType.MATERIAL_GOODS_ECO4]:
                                "Beni materiali eco 4.0"
                        },
                        errorTaxReliefDateRange:
                            "La data agevolazione deve essere compresa tra",
                        descMethod: "Metodo decrescente / Americano",
                        period: "Periodo d'utilizzo",
                        halfFirstYear: "50% primo anno",
                        descendingMethod: "Metodo decrescente / Americano",
                        minimum: "Minima",
                        accelerated: "Anticipata"
                    },
                    search: {
                        autocomplete: "Cerca cespite esistente",
                        filters: "Filtri",
                        dateFrom: "Da data",
                        dateTo: "A data",
                        supplier: "Fornitore",
                        table: {
                            subkind: "Sottospecie",
                            description: "Descrizione",
                            code: "Cod.identificativo",
                            purchaseDate: "Data di acquisto",
                            amount: "Valore"
                        }
                    },
                    incrementalSpending: {
                        existingAsset: "Cespite esistente",
                        existingAssetValue: "Valore cespite esistente",
                        residualAt: "Residuo al",
                        newOperation: "Nuova operazione",
                        type: "Tipo",
                        operationDate: "Data operazione",
                        sellingPercent: "Percentuale di vendita",
                        sellingValue: "Valore di vendita",
                        depreciationDuringYear: "Ammortamento in corso d'anno",
                        civilCapitalGain: "Plusvalenza civilistica",
                        civilCapitalLoss: "Minusvalenza civilistica",
                        taxCapitalGain: "Plusvalenza fiscale",
                        taxCapitalLoss: "Minusvalenza fiscale",
                        toStart: "Da ripartire",
                        ofWhich: "Di cui:",
                        nonTaxableCapitalGain: "Plusvalenza non tassabile",
                        nonDeductableCapitalLoss: "Minusvalenza non deducibile",
                        description: "Descrizione",
                        watchAllMovements: "Guarda tutti i movimenti",
                        identificationData: "Dati identificativi",
                        assets: "Cespite",
                        count: "Conto",
                        activationDate: "Data attivazione",
                        purchaseDate: "Data acquisto",
                        value: "Valore",
                        sale: "Vendita",
                        incrementalSpending: "Spesa incrementativa"
                    }
                },
                table: {
                    asset: "Cespite",
                    accessoryCharges: "Oneri accessori",
                    assetName: "Nome cespite",
                    subspecies: "Sottospecie",
                    taxDepreciationCriterion: "Criterio ammortamento fiscale",
                    incrementalSpending: "Spesa incrementativa",
                    criteria: {
                        MANUAL: "Manuale",
                        DYNAMIC: "Dinamica",
                        ORDINARY: "Di legge"
                    },
                    total: "Totale"
                },
                incrementalSpendingTooltip:
                    "Per procedere, seleziona “spesa incrementativa” per tutti i cespiti del raggruppamento"
            },
            usedGoods: {
                buttons: {
                    newUsedGoodGroup: "Nuovo gruppo da selezione"
                },
                totalAmount: "Totale beni usati",
                article: "Articoli",
                groupName: "Nome Gruppo",
                quantity: "Quantita",
                total: "Totale",
                increaseExistingGood: "Spesa incrementativa",
                usedGoodsRegistry: "Anagrafica bene usato",
                usedGoodData: "Dati identificativi",
                cost: "Costo",
                description: "Descrizione",
                save: "Salva",
                selectExistingUsedGood: "Seleziona bene esistente",
                searchUsedGood: "Cerca bene esistente",
                confirm: "Conferma"
            },
            fiscalPayments: {
                title: "Associa pagamento a fatture sospese ",
                table: {
                    reason: "Causale",
                    docNumber: "N. documento",
                    docDate: "Data documento",
                    amount: "Importo fattura",
                    residual: "Residuo",
                    collection: "Incasso"
                }
            },
            notFound:
                "La tua ricerca non ha prodotto nessun risultato. Prova a impostare filtri differenti o ad annullare tutti i filtri.",
            tabs: {
                accounting: "Contabilità",
                assets: "Cespiti",
                withholdings: "Ritenute"
            },
            withholdings: {
                totInvoiceNetVat: "Tot fattura netto IVA",
                drawer: {
                    title: "RITENUTA D'ACCONTO",
                    management: "Gestione ritenuta",
                    inps: "Gestione INPS",
                    closeButton: "Chiudi",
                    saveButton: "Salva"
                },
                header: {
                    reason: "Causale prestazione",
                    cuReason: "Causale per modello CU",
                    tributeCode: "Codice tributo"
                },
                inpsTab: {
                    socialInstituteTaxableAmount: "Imponibile INPS",
                    alreadySubjectedAmount: "Importo già assoggettato",
                    socialInstituteContributionRate:
                        "Percentuale contributo INPS",
                    recipientContribution: "Contributo a carico percipiente",
                    companyContribution: "Contributo a carico ditta",
                    activityStartDate: "Periodo attività dal",
                    activityEndDate: "Periodo attività al",
                    emensReportType: "EMENS - Tipo rapporto",
                    emensActivityCode: "EMENS - Codice attività",
                    emensOtherInsurance: "EMENS - Altra assicurazione"
                },
                managementTab: {
                    baseTaxableAmount: "Base imponibile ritenuta",
                    totalNetAmount: "Totale fatturato netto iva",
                    cashContribution: "Contributo integrativo",
                    notIncomeAmount: "Somme non costituenti reddito",
                    exemptIncome: " Reddito esente",
                    taxableAmount: "Imponibile ritenuta d'acconto",
                    withholdingRate: "Aliquota % ritenuta",
                    taxableContribution: "Imponibile ritenuta",
                    withHoldingTaxAmount: "Importo ritenuta a titolo d'imposta"
                },
                table: {
                    casualePerformance: "Causale Prestazione",
                    withholdingType: "Tipo ritenuta",
                    reasonCUModel: "Causale per modello CU",
                    integrativeContribution: "Contributo integrativo",
                    withholdingRate: "Aliquota % ritenuta",
                    withholdingAmount: "Importo ritenuta",
                    OTHER: "Altro ente previdenziale"
                }
            },
            header: {
                document: "Documento",
                numberAbbr: "n°",
                of: "del",
                received: "ricevuto il",
                totalDocument: "Totale Documento",
                tax: "Imposta",
                taxable: "Imponibile",
                title: {
                    numberPlaceholder: "Inserisci numero documento"
                },
                customerOrSupplier: {
                    client: "Cliente",
                    supplier: "Fornitore",
                    vatNumber: "P.IVA",
                    taxCode: "C.F",
                    sdiId: "SDI id",
                    privateSale: "Vendita a privato"
                },
                form: {
                    registrationDate: "Data di registrazione",
                    reason: "Causale",
                    sectionalActivity: "Sezionale - Attività",
                    vatRegime: "Regime IVA",
                    vatCompetence: "Competenza IVA",
                    vatCollectability: "Esigibilità IVA",
                    collectabilityList: {
                        SPLIT_PAYMENT: "Split payment",
                        DEFERRED: "IVA differita",
                        IMMEDIATE: "Esigibilità immediata"
                    }
                }
            },
            vatSummary: {
                title: "Riepilogo IVA",
                notFound: "Nessun dato",
                table: {
                    regime: "Regime",
                    taxable: "Imponibile",
                    rate: "Aliquota",
                    tax: "Imposta",
                    deductibility: "Detraibilità",
                    total: "Totale",
                    regimes: {
                        ORDINARIO: "Ordinario",
                        VENTILAZIONE: "Ventilazione",
                        VENTILAZIONE_MACELLAI: "Ventilazione macellai",
                        AGRICOLTURA_ESONERO: "Agricoltura esonero",
                        AGRICOLTURA_SPECIALE: "Agricoltura speciale",
                        MARGINE_ORDINARIO: "Margine ordinario",
                        MARGINE_GLOBALE: "Margine globale",
                        MARGINE_FORFETARIO: "Margine forfetario",
                        AUTOTRASP_C_TERZI: "Autotrasp c/terzi",
                        AGENZIA_VIAGGIO: "Agenzie viaggio",
                        EDITORIA_FORFETARIA: "Editoria forfetaria",
                        SUBFORNITURA: "Subfornitura",
                        INCOME_FORFAIT: "Forfettario"
                    }
                }
            }
        },
        ListAssets: {
            title: "Lista cespiti",
            table: {
                description: "Descrizione",
                identificationCode: "Cod. identificativo",
                purchaseDate: "Data acquisto",
                saleDate: "Data vendita",
                assetValue: "Valore cespite",
                totalDepricated: "Tot. ammortizzato",
                residualValue: "Valore residuo",
                state: "Stato",
                searchPlaceholder: "Cerca",
                status: {
                    all: "Tutti",
                    active: "Attivo",
                    notActive: "Non attivo"
                }
            },
            close: "Chiudi",
            revaluation: "Rivalutazione",
            elimination: "Eliminazione",
            errorLoadingTable: "Errore nel caricamento dei dati",
            totalAmount: "Tot cespiti",
            totalDepreciatedAmount: "Tot ammortizzati",
            totalResidualAmount: "Valore residuo tot.",
            accountAssetDetail: {
                names: {
                    fundAccount: "Conto fondi",
                    depreciationRateAccount: "Conto quote ammortamento",
                    capitalLossAccount: "Conto minusvalenze",
                    capitalGainAccount: "Conto plusvalenze",
                    toBeDividedCapitalGainAccount:
                        "Conto plusvalenze da ripartire",
                    revaluationAccount: "Conto rivalutazione",
                    anticipatedDepreciationRateAccount:
                        "Conto quote ammortamento anticipate",
                    nonDeductibleDepreciationRateAccount:
                        "Conto quote ammortamento indeducibile",
                    disposalAccount: "Conto eliminazione processo produttivo",
                    anticipatedDepreciationFundAccount:
                        "Conto fondo ammortamento anticipato",
                    nonTaxableCapitalGainAccount:
                        "Conto plusvalenza non tassabile",
                    nonDeductibleCostsAccount: "Conto costi indeducibili",
                    nonDeductibleCostAccount: "Conto costi indeducibili",
                    nonDeductibleDisposalAccount:
                        "Conto eliminazione non deducibile",
                    goodType:
                        "Tipo bene deduz. extracontabili (Quadro EC Rediti)",
                    parametersGoodType: "Tipo bene PARAMETRI",
                    professionalCapitalLossAccount:
                        "Conto minusvalenze professionisti",
                    professionleCapitalGainAccount:
                        "Conto plusvalenze professionisti",
                    otherTaxDebts: "Altri debiti tributari"
                }
            },
            notFound:
                "La tua ricerca non ha prodotto nessun risultato. Prova a impostare filtri differenti o ad annullare tutti i filtri.",
            drawerTitle: "Dati cespite",
            drawerLabels: {
                registry: "Anagrafica",
                depreciation: "Ammortamento",
                movements: "Movimenti"
            },
            movementDrawer: {
                revaluation: "Rivalutazione",
                elimination: "Eliminazione",
                drawerLoading:
                    "Caricamento dati in corso. Si prega di attendere.",
                assetMovements: "Movimenti cespite",
                addMovement: "Aggiungi movimenti",
                existingAssetValue: "Valore cespite esistente",
                residualStatutory: "Residuo civilistico",
                residualFiscal: "Residuo fiscale",
                residualIrap: "Residuo IRAP",
                residualCredit: "Residuo Credito",
                residualAt: "Residuo al",
                activationDate: "Data attivazione",
                lastUpdated: "Ultimo aggiornamento",
                import: "Importo",
                residual: "Residuo",
                buttons: {
                    STATUTORY: "Civilistico",
                    TAX: "Fiscale",
                    IRAP: "IRAP",
                    TAX_RELIEF: "Agevolazioni fiscali"
                },
                type: {
                    [MovementsType.PURCHASE]: {
                        DONE: "Acquisto"
                    },
                    [MovementsType.INCREASE]: {
                        DONE: "Incremento"
                    },
                    [MovementsType.SALE]: {
                        DONE: "Vendita"
                    },
                    [MovementsType.PARTIALLY_SALE]: {
                        DONE: "Parzialmente Venduto"
                    },
                    [MovementsType.DECOMISSIONED]: {
                        PENDING: "In Dismissione",
                        ERROR: "Errore Dismissione",
                        DONE: "Dismesso"
                    },
                    [MovementsType.PARTIALLY_DECOMISSIONED]: {
                        PENDING: "In Dismissione",
                        ERROR: "Errore Dismissione",
                        DONE: "Dismissione Parziale"
                    },
                    [MovementsType.DEPRECIATION]: {
                        PENDING: "In Ammortamento",
                        ERROR: "Errore Ammortamento",
                        DONE: "Ammortamento"
                    },
                    [MovementsType.REVALUATE]: {
                        PENDING: "In Rivalutazione",
                        ERROR: "Errore Rivalutazione",
                        DONE: "Rivalutazione"
                    },
                    [MovementsType.DEVALUATE]: {
                        PENDING: "In Svalutazione",
                        ERROR: "Errore Svalutazione",
                        DONE: "Svalutazione"
                    },
                    [MovementsType.DEVALUATE_ADJUSTMENT]: {
                        PENDING: "In Devalutazione",
                        ERROR: "Errore Devalutazione",
                        DONE: "Devalutazione"
                    }
                },
                backButton: "Movimenti cespiti",
                saleLogs: {
                    sale: "Vendita",
                    opsInfo: "Dati dell'operazione",
                    documentInfo: "Dati del documento",
                    documentDate: "Data documento",
                    documentNumber: "Numero documento",
                    clientCompanyName: "Ragione sociale cliente",
                    activitiesSection: "Sezionale attività",
                    saleDate: "Data vendita",
                    salePrice: "Prezzo di vendita",
                    salePercent: "% di vendita",
                    civilCapitalGain: "Plusvalenza civilistica",
                    civilCapitalLoss: "Minusvalenza civilistica",
                    taxCapitalGain: "Plusvalenza fiscale",
                    taxCapitalLoss: "Minusvalenza fiscale",
                    residualDepreciation: "Residuo da ammortizzare"
                },
                revaluationLogs: {
                    revaluation: "Rivalutazione",
                    opsInfo: "Dati dell'operazione",
                    revaluationDate: "Data rivaluatazione",
                    revaluationType: "Tipo rivalutazione",
                    revaluationScope: "Ambito di applicazione",
                    currentAssetValue: "Valore corrente del bene",
                    revaluationAmount: "Importo della rivalutazione"
                },
                purchaseLogs: {
                    initialPurchase: "Aquisto iniziale",
                    incrementalSpending: "Spesa incrementativa",
                    documentInfo: "Dati del documento",
                    documentDate: "Data documento",
                    documentNumber: "Numero documento",
                    supplierCompanyName: "Ragione sociale fornitore",
                    opsInfo: "Dati dell'operazione",
                    activitiesSection: "Sezionale attività",
                    purchaseDate: "Data aquisto",
                    assetValues: "Valore del cespite",
                    nonDeductibleVat: "Iva indetraibile",
                    carryingValue: "Totale da ammortizzare",
                    additionalCharge: "Oneri accessori"
                },
                disposalLogs: {
                    disposal: "Dismissione",
                    opsInfo: "Dati dell'operazione",
                    disposalDate: "Data eliminazione",
                    disposalPercent: "% di eliminazione",
                    civilCapitalGain: "Plusvalenza civilistica",
                    civilCapitalLoss: "Minusvalenza civilistica",
                    taxCapitalGain: "Plusvalenza fiscale",
                    taxCapitalLoss: "Minusvalenza fiscale",
                    residualDepreciation: "Residuo da ammortizzare"
                },
                depreciationLogs: {
                    depreciation: "Ammortamento",
                    opsInfo: "Dati dell'operazione",
                    depreciationDate: "Data ammortamento",
                    depreciationPercent: "% di ammortamento",
                    totalToBeDepreciated: "Totale da ammortizzare",
                    depreciationQuota: "Quota ammortamento",
                    residualDepreciation: "Residuo ammortizzabile",
                    accumulatedDepreciation: "Fondo ammortamento",
                    suspensionPercent: "% di sospensione"
                },
                revaluationSubsection: {
                    returnButton: "Torna a movimenti",
                    currentValue: "Valore corrente del cespite",
                    revaluationDate: "Data efficacia rivalutazione",
                    revaluationType: "Tipo di movimento",
                    revaluationCost: "Rivalutazione del costo storico",
                    revaluationCostAndFund:
                        "Rivalutazione del costo storico e del fondo",
                    decrementFund: "Decremento del fondo",
                    applicationFied: "Ambito di applicazione",
                    tax: "Fiscale",
                    statutory: "Civilistico",
                    statutoryAndTax: "Civilistico e Fiscale",
                    confirmRevaluation: "Conferma rivalutazione"
                },
                eliminationSubsection: {
                    returnButton: "Torna a movimenti",
                    deletionData: "Dati eliminazione",
                    accountingPreview: "Anteprima contabile",
                    accountingRecord: "Registrazione contabile",
                    sectional: "Sezionale",
                    accountingAccount: "Conto contabile",
                    accountingReason: "Causale contabile",
                    confirmElimination: "Conferma eliminazione",
                    eliminationAsset: "Dismissione Cespite",
                    credit: "Dare",
                    debit: "Avere",
                    elimination: "Data Eliminazione *",
                    selectDate: "Seleziona una data",
                    taxLoss: "Minusvalenza fiscale",
                    civilLoss: "Minusvalenza civilistica",
                    description: "Descrizione",
                    operationDescription:
                        "Inserisci qui eventuali dati aggiuntivi come: motivo dismissione, numero e data del verbale di distruzione o la denuncia di furto, smarrimento, ecc.",
                    eliminationPercent: "Percentuale eliminazione",
                    drawerButton: "Avanti",
                    existingAssetValue: "Valore cespite esistente",
                    residualAt: "Residuo al",
                    depreciationDuring: "Ammortamento in corso",
                    activationDate: "Data attivazione"
                }
            },
            depreciationDrawer: {
                taxDepreciation: "Amortamento fiscale",
                civilDepreciation: "Amortamento civilistico",
                taxCriteria: "Come da criteri fiscali",
                descendingMethod: "Metodo decrescente / Americano",
                usagePeriod: "Periodo d'utilizzo",
                fixedPercentage: "Percentuale fissa",
                halftFirstYear: "50% primo anno",
                ordinary: "Di legge",
                manual: "Manuale",
                dynamic: "Dinamica",
                reduced: "Minima",
                accelerated: "Anticipata"
            },
            drawerIdentifyingdata: "Dati identificativi",
            amount: "Valore",
            group: "Gruppo",
            kind: "Specie",
            subKind: "Sottospecie",
            materialGoods: "Bene Materiale",
            nonMaterialGoods: "Bene Immateriale",
            new: "Nuovo",
            used: "Usato",
            description: "Descrizione",
            availability: "Disponibilità e deducibilità*",
            activation: "Attivazione",
            disactivation: "Disattivazione",
            disponibility: "Disponibilità",
            reducedDeductibility: "Deducibilità ridotta",
            indentification: "Identificativo"
        },
        NewRegistration: {
            SelectReason: {
                title: "Seleziona la causale",
                selectReasonWarning:
                    "La causale determina i dettagli che dovrai aggiungere. Scegli con cura!",
                selectReason: "Seleziona la causale",
                goOn: "Avanti",
                cancel: "Annulla"
            },
            FillHeader: {
                title: "Inserisci i dati di testata",
                documentNumber: "Documento n°",
                ofThe: "del",
                of: "di",
                goOn: "Avanti",
                cancel: "Annulla"
            },
            AccountingLines: {
                title: "Compila di dati di contabilità"
            },
            account: "Contabilizza",
            update: "Aggiorna",
            delete: "Elimina",
            close: "Chiudi"
        },
        Withholdings: {
            title: "RITENUTE D'ACCONTO",
            notFound:
                "La tua ricerca non ha prodotto nessun risultato. Prova a impostare filtri differenti o ad annullare tutti i filtri.",
            active: "Attive",
            passive: "Passive",
            errorLoadingTable: "Errore nel caricamento dei dati",
            has: "Hai",
            withholdingCount: "ritenute da versare",
            table: {
                supplier: "Fornitore",
                customer: "Cliente",
                documentNumber: "N° documento",
                documentDate: "Data documento",
                documentAmount: "Importo documento",
                totalAmount: "Importo ritenuta",
                sentAmount: "Importo ritenuta inviata F24",
                residualAmount: "Importo residuo",
                noResultsActive: "Non ci sono ritenute attive",
                noResultsPassive: "Non ci sono ritenute passive"
            },
            filters: {
                toBePaid: "Da pagare",
                partialPaid: "Parzialmente pagate",
                paid: "Pagate"
            },
            drawer: {
                title: "RITENUTA D'ACCONTO",
                description: "Gestione INPS",
                managementLabel: "Gestione ritenuta",
                inpsLabel: "Gestione INPS",
                movementsLabel: "Movimenti",
                closeButton: "Chiudi",
                saveButton: "Salva",
                management: {
                    reason: "Causale prestazione",
                    cuReason: "Causale per modello CU",
                    tributeCode: "Codice tributo",
                    baseTaxableAmount: "Base imponibile ritenuta",
                    totalNetAmount: "Totale fatturato netto iva",
                    cashContribution: "Contributo integrativo",
                    notIncomeAmount: "Somme non costituenti reddito",
                    exemptIncome: " Reddito esente",
                    taxableAmount: "Imponibile ritenuta d'acconto",
                    withholdingRate: "Aliquota % ritenuta",
                    taxableWithholding: "Imponibile ritenuta",
                    withHoldingTaxAmount: "Importo ritenuta a titolo d'imposta"
                },
                inps: {
                    reason: "Causale prestazione",
                    cuReason: "Causale per modello CU",
                    tributeCode: "Codice tributo",
                    socialInstituteTaxableAmount: "Imponibile INPS",
                    alreadySubjectedAmount: "Importo già assoggettato",
                    socialInstituteContributionRate:
                        "Percentuale contributo INPS",
                    recipientContribution: "Contributo a carico percipiente",
                    companyContribution: "Contributo a carico ditta",
                    activityStartDate: "Periodo attività dal",
                    activityEndDate: "Periodo attività al",
                    emensReportType: "EMENS - Tipo rapporto",
                    emensActivityCode: "EMENS - Codice attività",
                    emensOtherInsurance: "EMENS - Altra assicurazione"
                }
            }
        },
        ListUsedGoods: {
            title: "GESTIONE BENI USATI",
            totalUsedGoods: "Tot beni usati",
            notFound:
                "La tua ricerca non ha prodotto nessun risultato. Prova a impostare filtri differenti o ad annullare tutti i filtri.",
            errorLoadingTable: "Errore nel caricamento dei dati",
            table: {
                description: "Descrizione",
                descPlaceholder: "Seleziona",
                identificationCode: "Cod. indetificativo",
                purchaseDate: "Data acquisto",
                saleDate: "Data vendita",
                totalPurchase: "Total acquisto",
                incrementalSpending: "Spesa incrementativa",
                marginAmount: "Margine lordo",
                status: "Stato",
                all: "Tutti",
                sold: "Venduto",
                inPlace: "Non venduto",
                statusSelect: {
                    SOLD: "Venduto",
                    IN_PLACE: "Non venduto"
                }
            }
        }
    },
    widgets: {
        Highlight: {
            title: "In evidenza",
            lastRegistrationDate: "Data ultima registrazione contabile",
            documentsContaining: "Documenti contenenti:",
            assets: "Cespiti:",
            witholdings: "Ritenute:",
            intrastat: "Intrastat:"
        },
        ToAccount: {
            toAccount: "Da contabilizzare",
            accountedThisYear: "Contabilizzati da inizio anno",
            purchaseInvoices: "Fatture d'acquisto",
            salesInvoices: "Fatture di vendita",
            receipts: "Corrispettivi",
            bankTransactions: "Movimenti bancari",
            other: "Altro",
            assets: "Cespiti",
            witholdings: "Ritenute",
            intrastat: "Intrastat"
        },
        VatSummary: {
            title: "Riepilogo IVA",
            vat: "IVA",
            periodicity: {
                [PeriodType.MONTHLY]: "mensile",
                [PeriodType.QUARTERLY]: "trimestrale",
                [PeriodType.MIXED]: " "
            }
        }
    }
});
